import * as React from "react";
import { Modal, Button } from "antd";
import WrappedNewWorkshopForm from "./NewWorkshopForm";
import { connect } from "react-redux";
import { IRootState } from "../../Store";

interface Props {
  successMsg: string;
}

const CreateWorkshopModal: React.FC<Props> = props => {
  const { successMsg } = props;
  const [isShown, setIsShown] = React.useState(false);

  React.useEffect(() => {
    if (successMsg) {
      setIsShown(false);
    }
  }, [successMsg, setIsShown]);

  const showModal = () => {
    setIsShown(true);
  };

  const handleCancel = () => {
    setIsShown(false);
  };

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Add new workshop
      </Button>
      <Modal
        title="Add new workshop"
        visible={isShown}
        onCancel={handleCancel}
        footer={null}
      >
        <WrappedNewWorkshopForm />
      </Modal>
    </div>
  );
};

export default connect((state: IRootState) => ({
  successMsg: state.workshop.createSuccessMsg
}))(CreateWorkshopModal);
