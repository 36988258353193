import { IWorkshopActions } from './actions';
import { IWorkshopStates } from './state';

const initialState: IWorkshopStates = {
  createSuccessMsg: '',
  confirmSuccessMsg: '',
  updateSuccessMsg: '',
  updateStatusSuccessMsg: '',
  updateLockStatusMsg: '',
  currentTeamLeader: [],
  currentTeamInfo: [],
  currentTeamNames: {
    team_1_name: '',
    team_2_name: '',
    team_3_name: '',
    team_4_name: '',
    team_5_name: '',
    team_6_name: '',
  },
  currentWorkShop: '',
  workshops: [],
  info: {
    id: '',
    team_1_name: '',
    team_2_name: '',
    team_3_name: '',
    team_4_name: '',
    team_5_name: '',
    team_6_name: '',
    status: false,
    locked: false,
    password: '',
  },
  boardBadges: {
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '6': 0,
  },
  editModalIsShown: false,
  newModalIsShown: false,
  msg: '',
};

export function workshopReducer(
  state: IWorkshopStates = initialState,
  actions: IWorkshopActions
): IWorkshopStates {
  switch (actions.type) {
    case 'SYNC_CURRENT_WORKSHOP':
      return {
        ...state,
        currentWorkShop: actions.id,
      };
    case 'GET_WORKSHOPS_SUCCESS':
      return {
        ...state,
        workshops: actions.workshops,
      };
    case 'SYNC_NEW_MODAL':
      return {
        ...state,
        newModalIsShown: actions.isShown,
      };
    case 'SYNC_TEAM_PLACEMENT_SUCCESS':
      return {
        ...state,
        boardBadges: actions.badges,
      };
    case 'UPDATE_STATUS_SUCCESS':
      return {
        ...state,
        updateStatusSuccessMsg: actions.updateStatusSuccessMsg,
      };
    case 'UPDATE_LOCK_STATUS_SUCCESS':
      return {
        ...state,
        updateLockStatusMsg: actions.updateLockStatusMsg,
        info: {
          ...state.info,
          locked: true,
        },
      };
    case 'UPDATE_WORKSHOP_SUCCESS':
      return {
        ...state,
        updateSuccessMsg: actions.updateSuccessMsg,
      };
    case 'SYNC_EDIT_MODAL':
      return {
        ...state,
        editModalIsShown: actions.isShown,
      };
    case 'SYNC_INFO_SUCCESS':
      return {
        ...state,
        info: actions.info,
      };
    case 'GET_CURRENT_WORKSHOP_SUCCESS':
      return {
        ...state,
        info: actions.workshop,
      };
    case 'CONFIRM_COMPLETE_SUCCESS':
      return {
        ...state,
        confirmSuccessMsg: actions.confirmSuccessMsg,
      };
    case 'CREATE_WORKSHOP_SUCCESS':
      return {
        ...state,
        createSuccessMsg: actions.createSuccessMsg,
      };
    case 'GET_CURRENT_POSITION': {
      return {
        ...state,
        boardBadges: actions.badges,
      };
    }
    case 'GET_CURRENT_TEAM_NAME': {
      return {
        ...state,
        currentTeamNames: actions.teamNames,
      };
    }
    case 'GET_TEAM_INFO_SUCCESS': {
      const memberIds = actions.teamInfo.map((m) => m.member_id);
      const updateLeaders = state.currentTeamLeader.filter((l) =>
        memberIds.includes(l.member_id)
      );

      return {
        ...state,
        currentTeamInfo: actions.teamInfo,
        currentTeamLeader: updateLeaders,
      };
    }
    case 'UPDATE_TEAM_LEADER_SUCCESS': {
      if (actions.currentTeamLeader) {
        return {
          ...state,
          currentTeamLeader: actions.currentTeamLeader,
        };
      } else {
        return {
          ...state,
          currentTeamLeader: [],
        };
      }
    }
    case 'GET_TEAM_LEADER_SUCCESS': {
      return {
        ...state,
        currentTeamLeader: actions.currentTeamLeader,
      };
    }

    case 'CLEAR_MESSAGE': {
      return {
        ...state,
        createSuccessMsg: '',
        confirmSuccessMsg: '',
        updateSuccessMsg: '',
        updateStatusSuccessMsg: '',
        updateLockStatusMsg: '',
        msg: '',
      };
    }

    case 'GET_TEAM_INFO_FAILED':
    case 'GET_TEAM_LEADER_FAILED':
    case 'UPDATE_TEAM_LEADER_FAILED':
    case 'GET_CURRENT_TEAM_NAME_FAILED':
    case 'GET_CURRENT_FAILED':
    case 'GET_WORKSHOPS_FAILED':
    case 'CONFIRM_COMPLETE_FAILED':
    case 'UPDATE_WORKSHOP_FAILED':
    case 'SYNC_TEAM_PLACEMENT_FAILED':
    case 'UPDATE_STATUS_FAILED':
    case 'CREATE_WORKSHOP_FAILED':
      return {
        ...state,
        msg: actions.msg,
      };
    default:
      return state;
  }
}
