import { Icon, Button } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { IBoardBadges } from "../../redux/workshop/state";
import { IRootState } from "../../Store";
import { WorkshopTeamNameModel } from "../../model";
import { QRCodeModal } from "./QRCodeModal";

interface Props {
  boardBadges: IBoardBadges;
  teamNames: WorkshopTeamNameModel;
  showQR?: boolean;
}

export const validateBoardNo = (val: number): boolean => {
  const range = [2, 3, 9, 13, 14, 20, 21, 25, 28, 31, 35, 36];
  const isValid = range.includes(Number(val));
  
  return !isValid;
};

const CurrentBadgePosition: React.FC<Props> = props => {
  const { boardBadges, teamNames, showQR } = props;
  const [shown, setShown] = React.useState(false);
  const [shownInfo, setShownInfo] = React.useState({ place: 1, name: "" });

  let arr: string[] = [];
  if (teamNames) {
    arr = arr
      .concat(teamNames.team_1_name)
      .concat(teamNames.team_2_name)
      .concat(teamNames.team_3_name)
      .concat(teamNames.team_4_name)
      .concat(teamNames.team_5_name)
      .concat(teamNames.team_6_name);
  }
  const style = {
    margin: "1rem",
    marginLeft: 0,
    fontSize: "1rem",
    width: 200
  };
  const badges = [
    {
      id: 1,
      place: boardBadges[1],
      color: "black",
      name: arr[0] ? arr[0] : null
    },
    {
      id: 2,
      place: boardBadges[2],
      color: "green",
      name: arr[1] ? arr[1] : null
    },
    {
      id: 3,
      place: boardBadges[3],
      color: "red",
      name: arr[2] ? arr[2] : null
    },
    {
      id: 4,
      place: boardBadges[4],
      color: "blue",
      name: arr[3] ? arr[3] : null
    },
    {
      id: 5,
      place: boardBadges[5],
      color: "yellow",
      name: arr[4] ? arr[4] : null
    },
    {
      id: 6,
      place: boardBadges[6],
      color: "brown",
      name: arr[5] ? arr[5] : null
    }
  ];

  const renderQRButton = (item) => {
    return (
      <Button
      size="default"
      type="default"
      style={{ marginRight: "0.5em" }}
      onClick={() => {
        setShown(true);
        setShownInfo({ place: item.place, name: item.name || "" });
      }}
      disabled={validateBoardNo(item.place)}
    >
      QR
    </Button>
    )
  }

  const renderContent = (item: {
    id: number;
    place: number;
    color: string;
    name: string | null;
  }) => {    
    if (item.name !== null) {
      return (
        <div style={style} key={`${item.id}-container`}>
         {showQR && renderQRButton(item)}
          <Icon
            key={`${item.id}-icon`}
            type="environment"
            theme="twoTone"
            twoToneColor={item.color}
            style={{ marginRight: 5 }}
          />
          <span>{`${item.name ? item.name : `Team ${item.id}`} : `}</span>
          {item.place}   
          <QRCodeModal
            shown={shown}
            setShown={setShown}
            content={`board-${shownInfo.place}`}
            addInfo={`${shownInfo.name} : ${shownInfo.place}`}
          />        
        </div>
      );
    }
  };

  return (
    <div>
      {badges.map(item => renderContent(item))}      
    </div>
  );
};

export default connect((state: IRootState) => ({
  boardBadges: state.workshop.boardBadges,
  teamNames: state.workshop.currentTeamNames
}))(CurrentBadgePosition);
