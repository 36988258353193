import { Icon } from "antd";
import * as React from "react";
import fadeIn from "react-animations/lib/fade-in";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import gameBoard from "../components/gameBoard2.jpg";
import CurrentBadgePosition from "../components/game-page/CurrentBadgePosition";
import { workshopTeamInfoModel } from "../model";
import {
  getTeamLeaderSuccess,
  syncTeamPlacement
} from "../redux/workshop/actions";
import {  getCurrentTeamName } from "../redux/workshop/thunk";
import { IBoardBadges } from "../redux/workshop/state";
import { getCurrentPosition } from "../redux/workshop/thunk";
import { ArThunkDispatch, IRootState } from "../Store";
import { socket } from "./GameRoomListPage";

const FadeInAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 2s ${FadeInAnimation};
`;
interface Props {
  boardBadges: IBoardBadges;
  match: match<{ id: string }>;
  syncTeamPlacement: (badges: IBoardBadges) => void;
  getCurrentPosition: (id: string) => void;
  getTeamLeaderSuccess: (currentTeamLeader: workshopTeamInfoModel[]) => void;
  getCurrentTeamName: (id: string) => void;
}

const GameBoard: React.FC<Props> = (props) => {
  const {
    boardBadges,
    syncTeamPlacement,
    match,
    getCurrentPosition,
    getTeamLeaderSuccess,
    getCurrentTeamName
  } = props;
  
  React.useEffect(() => {
    const id = match.params.id;
    if (id) {
      socket.on('adminUpdateTeamName', () => {
        setTimeout(() => {
          getCurrentTeamName(id);
        }, 2500);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  React.useEffect(() => {
    const id = match.params.id;
    if (id) {
      getCurrentPosition(id);
      getCurrentTeamName(id);
    }
  }, [match.params.id, getCurrentPosition, getCurrentTeamName]);

  React.useEffect(() => {
    const id = match.params.id;
    socket.emit("joinWorkshop", id);
    socket.on("updatedLeader", (data: any) => {
      if (data) {
        getTeamLeaderSuccess(data);
      }
    });
    return () => {
      socket.emit("leaveWorkshop", id);
    };
  }, [match.params.id, getTeamLeaderSuccess]);

  React.useEffect(() => {
    socket.emit("adminJoin", match.params.id);
    socket.on("receiveBadges", (data: any) => {
      if (data) {
        syncTeamPlacement(data);
      }
    });
    return () => {
      socket.emit("adminLeave", match.params.id);
    };
  }, [syncTeamPlacement, match.params.id]);

  const badges = [
    { id: 1, place: boardBadges[1], color: "black" },
    { id: 2, place: boardBadges[2], color: "green" },
    { id: 3, place: boardBadges[3], color: "red" },
    { id: 4, place: boardBadges[4], color: "blue" },
    { id: 5, place: boardBadges[5], color: "yellow" },
    { id: 6, place: boardBadges[6], color: "brown" },
  ];
  let arr: any[] = [];
  const gameGrid = [
    [6, 5, null, 4, null, 3, null, 2, 1],
    [7, 8, 9, null, null, 10, null, 11, 12],
    [18, 17, 16, null, 15, 14, null, 13, null],
    [19, 20, null, 21, null, null, 22, 23, null],
    [28, null, 27, null, 26, null, 25, null, 24],
    [null, 29, null, 30, null, 31, null, 32, null],
    [38, 37, 36, null, 35, null, 34, null, 33],
    [39, null, 40, null, 41, null, 42, null, null],
  ];
  const singleGrid = [1, 2, 6, 7, 8, 11, 13, 15, 17, 18, 19, 22, 24, 33];

  const renderBadge = (col: number | null, i: number) => {
    return badges.map((badge: { id: number; place: number; color: string }) => {
      if (col === badge.place) {
        arr.push(badge.place);
        const array = arr.filter((e) => e === badge.place);
        if (array.length > 1) {
          let style;
          if (badge.place === 12) {
            if (array.length === 2) {
              style = {
                paddingLeft: "2rem",
              };
            } else if (array.length === 3) {
              style = {
                paddingLeft: "3rem",
              };
            } else if (array.length === 4) {
              style = {
                paddingLeft: "4rem",
              };
            } else if (array.length === 5) {
              style = {
                paddingLeft: "3rem",
                paddingTop: "6rem",
              };
            } else if (array.length === 6) {
              style = {
                paddingLeft: "4rem",
                paddingTop: "6rem",
              };
            }
          } else if (singleGrid.find((e) => e === badge.place)) {
            if (array.length === 2) {
              style = {
                paddingLeft: "2rem",
              };
            } else if (array.length === 3) {
              style = {
                paddingLeft: "3rem",
              };
            } else if (array.length === 4) {
              style = {
                paddingLeft: "4rem",
              };
            } else if (array.length === 5) {
              style = {
                paddingLeft: "5rem",
              };
            } else if (array.length === 6) {
              style = {
                paddingLeft: "6rem",
              };
            }
          } else {
            if (array.length === 2) {
              style = {
                paddingLeft: "1rem",
              };
            } else if (array.length === 3) {
              style = {
                paddingLeft: "2rem",
              };
            } else if (array.length === 4) {
              style = {
                paddingLeft: "3rem",
              };
            } else if (array.length === 5) {
              style = {
                paddingLeft: "4rem",
              };
            } else if (array.length === 6) {
              style = {
                paddingLeft: "5rem",
              };
            }
          }
          return (
            <FadeInDiv key={`fade${i}-1`}>
              <Icon
                key={`${badge.id}-${i}`}
                type="environment"
                theme="twoTone"
                twoToneColor={badge.color}
                style={style}
                className="board-badge"
              />
            </FadeInDiv>
          );
        } else {
          return (
            <FadeInDiv key={`fade${i}-2`}>
              <Icon
                key={`${badge.id}-${i}`}
                type="environment"
                theme="twoTone"
                twoToneColor={badge.color}
                className="board-badge"
              />
            </FadeInDiv>
          );
        }
      } else {
        return null;
      }
    });
  };

  const renderClassName = (col: any, i: number, rowLength: number) => {
    let name = col !== null ? "board-square" : "board-null";
    if (i === 0) {
      name = name + "-head";
    } else if (i + 1 === rowLength) {
      name = name + "-tail";
    }
    return name;
  };

  return (
    <div>
      <img
        src={gameBoard}
        alt="board"
        style={{ height: 932.27, width: 1371, position: "absolute" }}
      />
      <div className="game-board-grid">
        {gameGrid.map((row) => (
          <div className="board-row" key={`${row}`}>
            {row.map((col: number | null, i) => (
              <div
                className={renderClassName(col, i, row.length)}
                key={`tie-${i}`}
              >
                <div className="board-badge-position" key={`col${i}`}>
                  {renderBadge(col, i)}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    
      <div style={{ position: "absolute", left: 1371, paddingLeft: 20 }}>
        <CurrentBadgePosition />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  boardBadges: state.workshop.boardBadges,
});
const mapDispatchToProps = (dispatch: ArThunkDispatch) => ({
  syncTeamPlacement: (badges: IBoardBadges) =>
    dispatch(syncTeamPlacement(badges)),
  getCurrentPosition: (id: string) => dispatch(getCurrentPosition(id)),
  getTeamLeaderSuccess: (currentTeamLeader: workshopTeamInfoModel[]) =>
    dispatch(getTeamLeaderSuccess(currentTeamLeader)),
    getCurrentTeamName: (id: string) => dispatch(getCurrentTeamName(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GameBoard);
