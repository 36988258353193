import { Icon, Layout, Menu } from "antd";
import * as React from "react";
import { NavLink, Route, Switch, match } from "react-router-dom";
import PhotoTable from "../components/photo-page/PhotoTable";
import GameRoomListPage from "./GameRoomListPage";
import GamePage from "./GamePage";
import { useDispatch } from "react-redux";
import { logout } from "../redux/auth/thunk";

const { Content, Footer, Sider } = Layout;
interface Prop {
  match: match<{ type: string }>;
}
export const Portal: React.FC<Prop> = prop => {
  const { match } = prop;
  const [collapsed, isCollapsed] = React.useState(false);
  const [selected, setSelected] = React.useState("game");
  const onCollapse = (collapsed: boolean) => {
    isCollapsed(collapsed);
  };
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (selected === "logout") {
      dispatch(logout());
    }
  }, [selected, dispatch]);

  React.useEffect(() => {
    setSelected(match.params.type);
  }, [match.params.type]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[selected]}>
          <Menu.Item key="game">
            <NavLink to="/portal/game" onClick={() => setSelected("game")}>
              <Icon type="pie-chart" />
              <span>Game</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="photo">
            <NavLink to="/portal/photo" onClick={() => setSelected("photo")}>
              <Icon type="desktop" />
              <span>Photo Management</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="logout">
            <div onClick={() => setSelected("logout")}>
              <Icon type="logout" />
              <span>Logout</span>
            </div>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content>
          <div
            className="container"
            style={{
              padding: 24,
              margin: 24,
              background: "#fff",
              minHeight: 360
            }}
          >
            <Switch>
              <Route path="/portal/photo" component={PhotoTable} />
              <Route path="/portal/game/:id" component={GamePage} />
              <Route path="/portal/game" component={GameRoomListPage} />
              <Route path="/portal/manage-admin" />
            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }} />
      </Layout>
    </Layout>
  );
};
