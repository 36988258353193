import { IAuthActions } from "./actions";
import { IAuthStates } from "./state";

const initialState: IAuthStates = {
  restoring: true,
  isAuthenticated: false,
  userInfo: {
    id: "",
    email: "",
    name: "",
    type: ""
  },
  msg: ""
};

export function authReducer(
  state: IAuthStates = initialState,
  actions: IAuthActions
): IAuthStates {
  switch (actions.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        restoring: false,
        isAuthenticated: true,
        userInfo: actions.userInfo
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        restoring: true,
        isAuthenticated: false,
        userInfo: {
          id: "",
          email: "",
          name: "",
          type: ""
        },
        msg: ""
      };
    case "LOGOUT_FAILED":
    case "LOGIN_FAILED":
      return {
        ...state,
        msg: actions.msg
      };
    default:
      return state;
  }
}
