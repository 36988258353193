import * as React from "react";
import { Form, InputNumber, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { connect } from "react-redux";
import { ArThunkDispatch, IRootState } from "../../Store";
import { IBoardBadges } from "../../redux/workshop/state";
import { syncTeamPlacement } from "../../redux/workshop/actions";
import { updateGameBoard } from "../../redux/workshop/thunk";
import { WorkshopTeamNameModel } from "../../model";
import { socket } from '../../page/GameRoomListPage';

interface Props {
  syncTeamPlacement: (badges: IBoardBadges) => void;
  updateGameBoard: (id: string, badge: IBoardBadges) => void;
  boardBadges: IBoardBadges;
  teamNames: WorkshopTeamNameModel;
  id: string;
}

const GameBoardControl: React.FC<Props & FormComponentProps> = props => {
  const { boardBadges, id, updateGameBoard, teamNames } = props;
  const { validateFields, getFieldDecorator, resetFields } = props.form;

  let arr: string[] = [];
  if (teamNames) {
    arr = arr
      .concat(teamNames.team_1_name)
      .concat(teamNames.team_2_name)
      .concat(teamNames.team_3_name)
      .concat(teamNames.team_4_name)
      .concat(teamNames.team_5_name)
      .concat(teamNames.team_6_name);
  }

  React.useEffect(() => {
    socket.emit("adminBadges", boardBadges, id);
  }, [boardBadges, id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateFields((err, values: IBoardBadges) => {
      if (!err) {
        const newValue = {
          1: boardBadges[1] + values[1],
          2: boardBadges[2] + values[2],
          3: boardBadges[3] + values[3],
          4: boardBadges[4] + values[4],
          5: boardBadges[5] + values[5],
          6: boardBadges[6] + values[6]
        };
        updateGameBoard(id, newValue);
        resetFields();
      }
    });
  };
  const renderFormItem = (item: string, i: number) => {
    if (item) {
      return (
        <Form.Item label={`${item} placement`} key={`${i}-form-input`}>
          {getFieldDecorator(`${(i + 1).toString()}`, { initialValue: 0 })(
            <InputNumber min={-6} max={6} />
          )}
        </Form.Item>
      );
    }
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {arr.map((item: string, i: number) => renderFormItem(item, i))}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const GameBoardControlFrom = Form.create({ name: "game-control-form" })(
  GameBoardControl
);

export default connect(
  (state: IRootState) => ({
    boardBadges: state.workshop.boardBadges,
    teamNames: state.workshop.currentTeamNames,
    id: state.workshop.currentWorkShop
  }),
  (dispatch: ArThunkDispatch) => ({
    syncTeamPlacement: (badges: IBoardBadges) =>
      dispatch(syncTeamPlacement(badges)),
    updateGameBoard: (id: string, badge: IBoardBadges) =>
      dispatch(updateGameBoard(id, badge))
  })
)(GameBoardControlFrom);
