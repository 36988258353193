import { Button, Input, message } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { connect } from "react-redux";
import { NewWorkshopFormModel, WorkshopInfoModel } from "../../model";
import { updateWorkshop } from "../../redux/workshop/thunk";
import { ArThunkDispatch, IRootState } from "../../Store";

interface Props {
  info: WorkshopInfoModel;
  successMsg: string;
  updateWorkshop: (id: string, form: NewWorkshopFormModel) => void;
}

const EditWorkshopForm: React.FC<Props & FormComponentProps> = props => {
  const { getFieldDecorator, validateFields } = props.form;
  const { successMsg, info, updateWorkshop } = props;
  const [isSubmit, setIsSub] = React.useState(false);
  React.useEffect(() => {
    if (successMsg) {
      message.success(successMsg);
    }
  }, [successMsg]);

  React.useEffect(() => {
    if (isSubmit) {
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    }
  }, [isSubmit]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    validateFields((err, values: NewWorkshopFormModel) => {
      if (!err) {
        updateWorkshop(info.id, values);
        setIsSub(true);
      }
    });
  };

  return (
    <Form className="new-workshop-form" onSubmit={handleSubmit}>
      <Form.Item label="Team 1 name">
        {getFieldDecorator("team_1_name", {
          initialValue: info.team_1_name
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Team 2 name">
        {getFieldDecorator("team_2_name", {
          initialValue: info.team_2_name
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Team 3 name">
        {getFieldDecorator("team_3_name", {
          initialValue: info.team_3_name
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Team 4 name">
        {getFieldDecorator("team_4_name", {
          initialValue: info.team_4_name
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Team 5 name">
        {getFieldDecorator("team_5_name", {
          initialValue: info.team_5_name
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Team 6 name">
        {getFieldDecorator("team_6_name", {
          initialValue: info.team_6_name
        })(<Input />)}
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className="new-workshop-form-button"
      >
        Update
      </Button>
    </Form>
  );
};

const WrappedEditWorkshopForm = Form.create({
  name: "edit-workshop-from"
})(EditWorkshopForm);

export default connect(
  (state: IRootState) => ({
    successMsg: state.workshop.updateSuccessMsg,
    info: state.workshop.info
  }),
  (dispatch: ArThunkDispatch) => ({
    updateWorkshop: (id: string, form: NewWorkshopFormModel) =>
      dispatch(updateWorkshop(id, form))
  })
)(WrappedEditWorkshopForm);
