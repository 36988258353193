import * as React from 'react';
import { Modal, Button, Popconfirm, Tag } from 'antd';

interface Props {
  setShown: (status: boolean) => void;
  shown: boolean;
  source: string;
  confirmMission: (status: string) => void;
  status: string | null;
}

export const PhotoModal: React.FC<Props> = (props) => {
  const { setShown, shown, source, confirmMission, status } = props;
  const isApproved = status === 'confirmed';

  return (
    <Modal
      visible={shown}
      onCancel={() => setShown(false)}
      footer={null}
      centered
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '80vh',
        }}
      >
        <img
          src={`${source}`}
          alt={`mission img`}
          style={{
            display: 'block',
            maxWidth: '100%',
            maxHeight: '65vh',
            width: 'auto',
            height: 'auto',
            marginBottom: '2rem',
          }}
        />

        {status ? (
          <Tag color={isApproved ? "green" : "red"}>{status.toUpperCase()}</Tag>
        ) : (
          <div>
            <Popconfirm
              title="Sure to confirm?"
              onConfirm={() => confirmMission('confirmed')}
            >
              <Button type="primary" size="default" style={{ marginRight: "1rem" }}>
                Confirm
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Reject photo?"
              onConfirm={() => confirmMission('rejected')}
            >
              <Button type="danger" size="default" ghost>
                Reject
              </Button>
            </Popconfirm>
          </div>
        )}
      </div>
    </Modal>
  );
};
