import { ArThunkDispatch } from "../../Store";
import { authorizedFetch } from "../fetch";
import { confirmMissionSuccess, failed, getAllPhotoSuccess } from "./action";

const { REACT_APP_API_SERVER } = process.env;

export function getAllPhoto() {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(`${REACT_APP_API_SERVER}/admin/photo`);
      const result = await res.json();
      if (result && res.status === 200) {
        dispatch(
          getAllPhotoSuccess(
            result.data.userList,
            result.data.photo,
            result.data.missionResult
          )
        );
      } else {
        dispatch(failed("GET_ALL_PHOTO_FAILED", result.msg));
      }
    } catch (e) {
      dispatch(failed("GET_ALL_PHOTO_FAILED", e + ""));
    }
  };
}

export function confirmMission(member_id: string, mission_id: string, status: string) {
  return async (dispatch: ArThunkDispatch) => {
    const res = await authorizedFetch( 
      `${REACT_APP_API_SERVER}/admin/mission/${member_id}/${mission_id}`,
      {
        method: "POST",
        body: JSON.stringify({ status })
      }
    );
    const result = await res.json();
    if (result && res.status === 200) {
      dispatch(
        confirmMissionSuccess(
          result.userList,
          result.photo,
          result.missionResult
        )
      );
    } else {
      dispatch(failed("CONFIRM_MISSION_FAILED", result.msg));
    }
  };
}
