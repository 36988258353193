import { userPhotoModel, userListModel, userMissionResultModel } from "./state";

type GET_ALL_PHOTO_SUCCESS = "GET_ALL_PHOTO_SUCCESS";
type CONFIRM_MISSION_SUCCESS = "CONFIRM_MISSION_SUCCESS";
type FAILED = "GET_ALL_PHOTO_FAILED" | "CONFIRM_MISSION_FAILED";

export function getAllPhotoSuccess(
  userList: userListModel[],
  photos: userPhotoModel[],
  missionResult: userMissionResultModel[]
): IGetAllPhotoSuccess {
  return {
    type: "GET_ALL_PHOTO_SUCCESS",
    userList,
    photos,
    missionResult
  };
}

export function confirmMissionSuccess(
  userList: userListModel[],
  photos: userPhotoModel[],
  missionResult: userMissionResultModel[]
): IConfirmMissionSuccess {
  return {
    type: "CONFIRM_MISSION_SUCCESS",
    userList,
    photos,
    missionResult
  };
}

export function failed(type: FAILED, msg: string): IFailedActions {
  return {
    type,
    msg
  };
}
interface IConfirmMissionSuccess {
  type: CONFIRM_MISSION_SUCCESS;
  userList: userListModel[];
  photos: userPhotoModel[];
  missionResult: userMissionResultModel[];
}
interface IGetAllPhotoSuccess {
  type: GET_ALL_PHOTO_SUCCESS;
  userList: userListModel[];
  photos: userPhotoModel[];
  missionResult: userMissionResultModel[];
}

interface IFailedActions {
  type: FAILED;
  msg: string;
}
export type IPhotoActions =
  | IConfirmMissionSuccess
  | IGetAllPhotoSuccess
  | IFailedActions;
