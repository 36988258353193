import { Button, Form, Icon, Input, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React from "react";
import { connect } from "react-redux";
import { IRootState, ArThunkDispatch } from "../Store";
import { login } from "../redux/auth/thunk";
import appImg from "../components/appIcon.png";

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}
interface Props {
  login: (email: string, password: string) => void;
  msg: string;
}
interface State {
  showAlert: boolean;
}
class LoginForm extends React.Component<FormComponentProps & Props, State> {
  state = {
    showAlert: false
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.showAlert && this.state.showAlert && this.props.msg) {
      setTimeout(() => {
        this.setState({ showAlert: false });
      }, 4000);
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.login(values.email, values.password);
        this.setState({ showAlert: true });
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;
    const { showAlert } = this.state;

    // Only show error after a field is touched.
    const emailError = isFieldTouched("email") && getFieldError("email");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");

    return (
      <div style={{
        background: "#f6f6f6",
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
      }}> 
        {showAlert && this.props.msg && <Alert message={this.props.msg} type="error" banner showIcon style={{ width: '100%'}}/>}
        <img
          src={appImg}
          alt="app icon"
          style={{
            height: 100,
            width: 100,
            margin: '3rem',
            borderRadius: 20,
            filter: 'drop-shadow(0 0 0.5rem #c9c9c9)',
          }} 
        />
        <div style={{
          background: '#fff',
          width: "40%",
          minWidth: 300,
          maxWidth: 340,
          padding: "2rem",
          borderRadius: 6,
          borderColor: "#d9d9d9",
          borderWidth: 0.5,
          borderStyle: "solid",
        }}> 
          <Form onSubmit={this.handleSubmit}>
            <Form.Item
              validateStatus={emailError ? "error" : ""}
              help={emailError || ""}
            >
              {getFieldDecorator("email", {
                rules: [{ required: true, message: "Please input your email" }]
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Email"
                  size="large"
                /> 
              )}
            </Form.Item>
            <Form.Item
              validateStatus={passwordError ? "error" : ""}
              help={passwordError || ""}
            >
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "Please input your Password" }]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Password"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
                size="large"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
export const WrappedHorizontalLoginForm = Form.create({
  name: "login"
})(LoginForm);

export default connect(
  (state: IRootState) => ({
    msg: state.auth.msg,
  }),
  (dispatch: ArThunkDispatch) => ({
    login: (email: string, password: string) => dispatch(login(email, password))
  })
)(WrappedHorizontalLoginForm);
