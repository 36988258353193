import { Button, Input, message } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { ArThunkDispatch, IRootState } from "../../Store";
import { NewWorkshopFormModel } from "../../model";
import { createWorkshop } from "../../redux/workshop/thunk";
import { connect } from "react-redux";

interface Props {
  createWorkshop: (form: NewWorkshopFormModel) => void;
  successMsg: string;
}

const NewWorkshopForm: React.FC<Props & FormComponentProps> = props => {
  const { getFieldDecorator, validateFields } = props.form;
  const { createWorkshop, successMsg } = props;
  const [isSubmit, setIsSub] = React.useState(false);

  React.useEffect(() => {
    if (isSubmit) {
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    }
  }, [isSubmit]);

  React.useEffect(() => {
    if (successMsg) {
      message.success(successMsg);
    }
  }, [successMsg]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    validateFields((err, values: NewWorkshopFormModel) => {
      if (!err) {
        createWorkshop(values);
        setIsSub(true);
      }
    });
  };
  const style = { width: "9rem" };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Form className="new-workshop-form" onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator("team_1_name")(
            <Input placeholder="Team 1 name" style={style} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("team_2_name")(
            <Input placeholder="Team 2 name" style={style} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("team_3_name")(
            <Input placeholder="Team 3 name" style={style} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("team_4_name")(
            <Input placeholder="Team 4 name" style={style} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("team_5_name")(
            <Input placeholder="Team 5 name" style={style} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("team_6_name")(
            <Input placeholder="Team 6 name" style={style} />
          )}
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="new-workshop-form-button"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

const WrappedNewWorkshopForm = Form.create({
  name: "new-workshop-from"
})(NewWorkshopForm);

export default connect(
  (state: IRootState) => ({
    successMsg: state.workshop.createSuccessMsg
  }),
  (dispatch: ArThunkDispatch) => ({
    createWorkshop: (form: NewWorkshopFormModel) =>
      dispatch(createWorkshop(form))
  })
)(WrappedNewWorkshopForm);
