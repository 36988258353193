import * as React from "react";
import { Modal } from "antd";

const QRCode = require("qrcode.react");
interface Props {
  setShown: (status: boolean) => void;
  shown: boolean;
  content: string;
  addInfo?: string;
}

export const QRCodeModal: React.FC<Props> = props => {
  const { setShown, shown, content, addInfo } = props;
  return (
    <Modal
      visible={shown}
      onCancel={() => setShown(false)}
      footer={null}
      centered
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <QRCode value={content} size={256} />
        {addInfo && <p style={{ alignSelf: "center", marginTop: "1rem", fontSize: "1.5rem" }}>{addInfo}</p>}
      </div>
    </Modal>
  );
};
