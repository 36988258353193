import * as React from "react";
import WorkShopTable from "../components/game-page/WorkShopTable";
import { connect } from "react-redux";
import { ArThunkDispatch, IRootState } from "../Store";
import { getAllWorkShop } from "../redux/workshop/thunk";
import CreateWorkshopModal from "../components/game-page/CreateWorkshopModal";
import { message } from "antd";
import io from "socket.io-client";

const { REACT_APP_API_SERVER } = process.env;
export const socket = io(`${REACT_APP_API_SERVER}`);

interface Props {
  getAllWorkShop: () => void;
  updateStatusSuccessMsg: string;
}

const GameRoomListPage: React.FC<Props> = props => {
  const { getAllWorkShop, updateStatusSuccessMsg } = props;

  React.useEffect(() => {  
    getAllWorkShop();
  }, [getAllWorkShop]);

  React.useEffect(() => {
    if (updateStatusSuccessMsg) {
      message.success(`${updateStatusSuccessMsg}, Please wait.`);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }, [updateStatusSuccessMsg]);
  return (
    <div>
      <div style={{ marginBottom: "2rem" }}>
        <CreateWorkshopModal />
      </div>
      <div>
        <WorkShopTable />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  updateStatusSuccessMsg: state.workshop.updateStatusSuccessMsg
});
const mapDispatchToProps = (dispatch: ArThunkDispatch) => ({
  getAllWorkShop: () => dispatch(getAllWorkShop())
});

export default connect(mapStateToProps, mapDispatchToProps)(GameRoomListPage);
