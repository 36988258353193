import { IPhotoStates } from "./state";
import { IPhotoActions } from "./action";

const initialState: IPhotoStates = {
  userList: [],
  photos: [],
  missionResult: [],
  msg: ""
};

export function photoReducer(
  state: IPhotoStates = initialState,
  action: IPhotoActions
): IPhotoStates {
  switch (action.type) {
    case "CONFIRM_MISSION_SUCCESS":
      return {
        ...state,
        userList: action.userList,
        photos: action.photos,
        missionResult: action.missionResult
      };
    case "GET_ALL_PHOTO_SUCCESS":
      return {
        ...state,
        userList: action.userList,
        photos: action.photos,
        missionResult: action.missionResult
      };

    case "CONFIRM_MISSION_FAILED":
    case "GET_ALL_PHOTO_FAILED":
      return {
        ...state,
        msg: action.msg
      };
    default:
      return state;
  }
}
