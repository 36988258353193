import { Button, Tag, Popover } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { updateLockStatus } from '../../redux/workshop/thunk';
import { ArThunkDispatch, IRootState } from '../../Store';
import { WorkshopInfoModel } from '../../model';

interface Props {
  info: WorkshopInfoModel;
  updateLockStatus: (id: string) => void;
}

const LockControl: React.FC<Props> = (props) => {
  const { info, updateLockStatus } = props;

  if (info.locked) {
    return (
      <Popover
        placement="left"
        title="Workshop is now locked"
        content={
          <div>
            <p>New participants can join with password:</p>
            <p>
              <strong>{info.password}</strong>
            </p>
          </div>
        }
      >
        <Tag color="gray">LOCKED</Tag>
      </Popover>
    );
  }
  return (
    <Button
      type="primary"
      icon="flag"
      onClick={() => updateLockStatus(info.id)}
    >
      Game Start
    </Button>
  );
};

export default connect(
  (state: IRootState) => ({
    info: state.workshop.info,
  }),
  (dispatch: ArThunkDispatch) => ({
    updateLockStatus: (id: string) => dispatch(updateLockStatus(id)),
  })
)(LockControl);
