import {
  WorkshopInfoModel,
  workshopTeamInfoModel,
  WorkshopTeamNameModel
} from "../../model";
import { IBoardBadges, IWorkShop } from "./state";

type GET_WORKSHOPS_SUCCESS = "GET_WORKSHOPS_SUCCESS";
type GET_CURRENT_WORKSHOP_SUCCESS = "GET_CURRENT_WORKSHOP_SUCCESS";
type CREATE_WORKSHOP_SUCCESS = "CREATE_WORKSHOP_SUCCESS";
type UPDATE_WORKSHOP_SUCCESS = "UPDATE_WORKSHOP_SUCCESS";
type CONFIRM_COMPLETE_SUCCESS = "CONFIRM_COMPLETE_SUCCESS";
type UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
type UPDATE_LOCK_STATUS_SUCCESS = "UPDATE_LOCK_STATUS_SUCCESS";
type SYNC_TEAM_PLACEMENT_SUCCESS = "SYNC_TEAM_PLACEMENT_SUCCESS";
type SYNC_CURRENT_WORKSHOP = "SYNC_CURRENT_WORKSHOP";
type SYNC_INFO_SUCCESS = "SYNC_INFO_SUCCESS";
type SYNC_NEW_MODAL = "SYNC_NEW_MODAL";
type SYNC_EDIT_MODAL = "SYNC_EDIT_MODAL";
type GET_CURRENT_POSITION = "GET_CURRENT_POSITION";
type GET_CURRENT_TEAM_NAME = "GET_CURRENT_TEAM_NAME";
type GET_TEAM_INFO_SUCCESS = "GET_TEAM_INFO_SUCCESS";
type UPDATE_TEAM_LEADER_SUCCESS = "UPDATE_TEAM_LEADER_SUCCESS";
type GET_TEAM_LEADER_SUCCESS = "GET_TEAM_LEADER_SUCCESS";
type CLEAR_MESSAGE = "CLEAR_MESSAGE";
type REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";

type FAILED =
  | "GET_TEAM_LEADER_FAILED"
  | "UPDATE_TEAM_LEADER_FAILED"
  | "GET_CURRENT_TEAM_NAME_FAILED"
  | "GET_TEAM_INFO_FAILED"
  | "GET_CURRENT_FAILED"
  | "CREATE_WORKSHOP_FAILED"
  | "SYNC_TEAM_PLACEMENT_FAILED"
  | "GET_WORKSHOPS_FAILED"
  | "GET_CURRENT_WORKSHOP_FAILED"
  | "CONFIRM_COMPLETE_FAILED"
  | "UPDATE_STATUS_FAILED"
  | "UPDATE_LOCK_STATUS_FAILED"
  | "UPDATE_WORKSHOP_FAILED"
  | "REMOVE_USER_FAILED";

export function createWorkshopSuccess(
  createSuccessMsg: string
): ICreateSuccess {
  return {
    type: "CREATE_WORKSHOP_SUCCESS",
    createSuccessMsg
  };
}

export function confirmCompleteSuccess(
  confirmSuccessMsg: string
): IConfirmSuccess {
  return {
    type: "CONFIRM_COMPLETE_SUCCESS",
    confirmSuccessMsg
  };
}

export function syncInfoSuccess(info: WorkshopInfoModel): ISyncInfoSuccess {
  return {
    type: "SYNC_INFO_SUCCESS",
    info
  };
}

export function syncEditModalSuccess(isShown: boolean): ISyncModalSuccess {
  return {
    type: "SYNC_EDIT_MODAL",
    isShown
  };
}

export function syncNewModalSuccess(isShown: boolean): ISyncNewModalSuccess {
  return {
    type: "SYNC_NEW_MODAL",
    isShown
  };
}

export function updateStatusSuccess(
  updateStatusSuccessMsg: string
): IUpdateStatusSuccess {
  return {
    type: "UPDATE_STATUS_SUCCESS",
    updateStatusSuccessMsg
  };
}

export function updateLockStatusSuccess(
  updateLockStatusMsg: string
): IUpdateLockStatusSuccess {
  return {
    type: "UPDATE_LOCK_STATUS_SUCCESS",
    updateLockStatusMsg
  };
}

export function clearMessageSuccess(): IClearMessageSuccess {
  return {
    type: "CLEAR_MESSAGE"
  };
}

export function getAllWorkshopSuccess(
  workshops: IWorkShop[]
): IGetWorkShopsSuccess {
  return {
    type: "GET_WORKSHOPS_SUCCESS",
    workshops
  };
}

export function getCurrentWorkshopSuccess(
  workshop: IWorkShop
): IGetCurrentWorkShopSuccess {
  return {
    type: "GET_CURRENT_WORKSHOP_SUCCESS",
    workshop
  };
}

export function updateInfoSuccess(
  updateSuccessMsg: string
): IUpdateInfoSuccess {
  return {
    type: "UPDATE_WORKSHOP_SUCCESS",
    updateSuccessMsg
  };
}

export function syncTeamPlacement(
  badges: IBoardBadges
): ISyncTeamPlacementSuccess {
  return {
    type: "SYNC_TEAM_PLACEMENT_SUCCESS",
    badges
  };
}

export function syncCurrentWorkshop(id: string): SyncCurrentWorkshop {
  return {
    type: "SYNC_CURRENT_WORKSHOP",
    id
  };
}

export function getCurrentPositionSuccess(
  badges: IBoardBadges
): ICurrentBoardPositionSuccess {
  return {
    type: "GET_CURRENT_POSITION",
    badges
  };
}
export function getCurrentTeamNameSuccess(
  teamNames: WorkshopTeamNameModel
): ICurrentTeamNameSuccess {
  return {
    type: "GET_CURRENT_TEAM_NAME",
    teamNames
  };
}

export function getTeamInfoSuccess(
  teamInfo: workshopTeamInfoModel[]
): ITeamInfoSuccess {
  return {
    type: "GET_TEAM_INFO_SUCCESS",
    teamInfo
  };
}

export function updateTeamLeaderSuccess(
  currentTeamLeader: workshopTeamInfoModel[]
): IUpdateLeaderSuccess {
  return {
    type: "UPDATE_TEAM_LEADER_SUCCESS",
    currentTeamLeader
  };
}

export function getTeamLeaderSuccess(
  currentTeamLeader: workshopTeamInfoModel[]
): IGetLeaderSuccess {
  return {
    type: "GET_TEAM_LEADER_SUCCESS",
    currentTeamLeader
  };
}

export function removeUserSuccess(
  updateSuccessMsg: string
): IRemoveUserSuccess {
  return {
    type: "REMOVE_USER_SUCCESS",
    updateSuccessMsg
  };
}

export function failed(type: FAILED, msg: string): IFailedActions {
  return {
    type,
    msg
  };
}
interface IGetLeaderSuccess {
  type: GET_TEAM_LEADER_SUCCESS;
  currentTeamLeader: workshopTeamInfoModel[];
}
interface IUpdateLeaderSuccess {
  type: UPDATE_TEAM_LEADER_SUCCESS;
  currentTeamLeader: workshopTeamInfoModel[];
}
interface ITeamInfoSuccess {
  type: GET_TEAM_INFO_SUCCESS;
  teamInfo: workshopTeamInfoModel[];
}
interface ICurrentTeamNameSuccess {
  type: GET_CURRENT_TEAM_NAME;
  teamNames: WorkshopTeamNameModel;
}
interface ICurrentBoardPositionSuccess {
  type: GET_CURRENT_POSITION;
  badges: IBoardBadges;
}
interface ISyncTeamPlacementSuccess {
  type: SYNC_TEAM_PLACEMENT_SUCCESS;
  badges: IBoardBadges;
}

interface IUpdateStatusSuccess {
  type: UPDATE_STATUS_SUCCESS;
  updateStatusSuccessMsg: string;
}
interface IClearMessageSuccess {
  type: CLEAR_MESSAGE;
}
interface IUpdateLockStatusSuccess {
  type: UPDATE_LOCK_STATUS_SUCCESS;
  updateLockStatusMsg: string;
}
interface SyncCurrentWorkshop {
  type: SYNC_CURRENT_WORKSHOP;
  id: string;
}
interface ISyncModalSuccess {
  type: SYNC_EDIT_MODAL;
  isShown: boolean;
}
interface ISyncNewModalSuccess {
  type: SYNC_NEW_MODAL;
  isShown: boolean;
}
interface ISyncInfoSuccess {
  type: SYNC_INFO_SUCCESS;
  info: WorkshopInfoModel;
}
interface IGetWorkShopsSuccess {
  type: GET_WORKSHOPS_SUCCESS;
  workshops: IWorkShop[];
}
interface IGetCurrentWorkShopSuccess {
  type: GET_CURRENT_WORKSHOP_SUCCESS;
  workshop: IWorkShop;
}
interface IUpdateInfoSuccess {
  type: UPDATE_WORKSHOP_SUCCESS;
  updateSuccessMsg: string;
}
interface ICreateSuccess {
  type: CREATE_WORKSHOP_SUCCESS;
  createSuccessMsg: string;
}

interface IConfirmSuccess {
  type: CONFIRM_COMPLETE_SUCCESS;
  confirmSuccessMsg: string;
}

interface IRemoveUserSuccess {
  type: REMOVE_USER_SUCCESS;
  updateSuccessMsg: string;
}

interface IFailedActions {
  type: FAILED;
  msg: string;
}

export type IWorkshopActions =
  | IGetLeaderSuccess
  | IUpdateLeaderSuccess
  | ITeamInfoSuccess
  | ICurrentTeamNameSuccess
  | ICurrentBoardPositionSuccess
  | ICreateSuccess
  | ISyncInfoSuccess
  | IConfirmSuccess
  | ISyncModalSuccess
  | IUpdateStatusSuccess
  | IUpdateLockStatusSuccess
  | IGetWorkShopsSuccess
  | IGetCurrentWorkShopSuccess
  | ISyncTeamPlacementSuccess
  | SyncCurrentWorkshop
  | ISyncNewModalSuccess
  | IUpdateInfoSuccess
  | IClearMessageSuccess
  | IRemoveUserSuccess
  | IFailedActions;
