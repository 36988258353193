import axios from "axios";
import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { ArThunkDispatch } from "../../Store";
import { loginSuccess, logoutSuccess, failed } from "./actions";
const { REACT_APP_API_SERVER } = process.env;

export function logout() {
  return async (dispatch: Dispatch) => {
    localStorage.removeItem("token");
    dispatch(logoutSuccess());
    dispatch(push("/"));
  };
}

export function login(email: string, password: string) {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const res = await axios.post(`${REACT_APP_API_SERVER}/auth/login`, {
        email,
        password
      });
      if (res.status === 200 && res.data.success === true) {
        localStorage.setItem("token", res.data.token);
        dispatch(loginSuccess(res.data.userInfo));
        dispatch(push("/portal/game"));
      } else if (res.status === 200 && res.data.success === false) {
        dispatch(logout());
        dispatch(failed("LOGIN_FAILED", res.data.msg));
      }
    } catch (e) {
      dispatch(failed("LOGIN_FAILED", e + ""));
    }
  };
}

export function restoreLogin() {
  return async (dispatch: ArThunkDispatch) => {
    const token = localStorage.getItem("token");
    if (token != null) {
      const res = await axios.get(`${REACT_APP_API_SERVER}/user/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (
        res.status === 200 &&
        res.data.success === true &&
        res.data.userInfo
      ) {
        dispatch(loginSuccess(res.data.userInfo));
      } else {
        dispatch(logout());
      }
      return;
    } else {
      dispatch(logout());
      dispatch(push("/"));
    }
  };
}
