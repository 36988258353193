import { userInfoModel } from "../../model";

type LOGIN_SUCCESS = "LOGIN_SUCCESS";
type LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
type FAILED = "LOGIN_FAILED" | "LOGOUT_FAILED";

export function logoutSuccess(): ILogoutSuccess {
  return {
    type: "LOGOUT_SUCCESS"
  };
}

export function loginSuccess(
  userInfo: userInfoModel,
): ILoginSuccess {
  return {
    type: "LOGIN_SUCCESS",
    userInfo,
  };
}

export function failed(type: FAILED, msg: string): IFailedActions {
  return {
    type,
    msg
  };
}

interface ILogoutSuccess {
  type: LOGOUT_SUCCESS;
}

interface ILoginSuccess {
  type: LOGIN_SUCCESS;
  userInfo: userInfoModel;
}

interface IFailedActions {
  type: FAILED;
  msg: string;
}

export type IAuthActions = ILoginSuccess | IFailedActions | ILogoutSuccess;
