import { Button, Divider, Popconfirm, Table, Tag, Icon } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { WorkshopInfoModel } from "../../model";
import { syncEditModalSuccess, syncInfoSuccess } from "../../redux/workshop/actions";
import { IWorkShop } from "../../redux/workshop/state";
import { updateStatus } from "../../redux/workshop/thunk";
import { ArThunkDispatch, IRootState } from "../../Store";
import EditInfoModal from "./EditInfoModal";
import { QRCodeModal } from "./QRCodeModal";

interface Props {
  isShown: boolean;
  workshops: IWorkShop[];
  syncInfoSuccess: (info: WorkshopInfoModel) => void;
  syncEditModalSuccess: (isShown: boolean) => void;
  updateStatus: (id: string) => void;
}

const WorkShopTable: React.FC<Props> = props => {
  const {
    syncInfoSuccess,
    syncEditModalSuccess,
    isShown,
    workshops,
    updateStatus
  } = props;
  const [shown, setShown] = React.useState(false);
  const columns = [
    {
      title: "Workshop ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        // eslint-disable-next-line
        <Link to={`/workshop/${id}`} target="_blank">
          <Icon type="environment" theme="twoTone" style={{ fontSize: 18 }}/> {id}
        </Link>
      ),
      sorter: (a, b) => a.id - b.id,
      width: 120
    },
    {
      title: "Team 1",
      dataIndex: "team_1_name",
      key: "team_1_name"
    },
    {
      title: "Team 2",
      dataIndex: "team_2_name",
      key: "team_2_name"
    },
    {
      title: "Team 3",
      dataIndex: "team_3_name",
      key: "team_3_name"
    },
    {
      title: "Team 4",
      dataIndex: "team_4_name",
      key: "team_4_name"
    },
    {
      title: "Team 5",
      dataIndex: "team_5_name",
      key: "team_5_name"
    },
    {
      title: "Team 6",
      dataIndex: "team_6_name",
      key: "team_6_name"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: boolean) => {
        let content;
        let color;
        if (status) {
          content = "in progress";
          color = "green";
        } else {
          content = "closed";
          color = "red";
        }
        return <Tag color={color}>{content.toUpperCase()}</Tag>;
      },
      filters: [
        {
          text: 'In progress',
          value: 'open',
        },
        {
          text: 'Closed', 
          value: 'closed',
        },
      ],
      onFilter: (value, record) => value === 'open' ? record.status : !record.status ,
    },
    {
      title: "Locked",
      dataIndex: "locked",
      key: "locked",
      render: (lock: boolean) => {
        let content;
        let color;
        if (lock) {
          content = "locked";
          color = "gray";
        } else {
          content = "open";
          color = "green";
        }
        return <Tag color={color}>{content.toUpperCase()}</Tag>;
      },
      filters: [
        {
          text: 'Open',
          value: 'open',
        },
        {
          text: 'Locked',
          value: 'locked',
        },
      ],
      onFilter: (value, record) => value === 'locked' ? record.locked : !record.locked ,
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "action",
      render: (status: boolean, record: WorkshopInfoModel) => (
        <span>         
          {record.status && (
            <Button size="small" type="default" onClick={() => setShown(true)}>
              QR
            </Button>
          )}
          {status && <Divider type="vertical" />}
          {record.status && (
            <Button
              ghost
              size="small"
              type="primary"
              onClick={() => {
                syncInfoSuccess(record);
                syncEditModalSuccess(!isShown);
              }}
            >
              Edit
            </Button>
          )}
          {status && <Divider type="vertical" />}
          {record.status && (
            <Button
              size="small"
              type="primary"
              onClick={() => syncInfoSuccess(record)}
            >
              <Link to={`/portal/game/${record.id}`}>Enter</Link>
            </Button>
          )}
          {status && (
            <Popconfirm
              title="Sure to complete?"
              onConfirm={() => updateStatus(record.id)}
            >
              <a href="/#"><p style={{ paddingTop: 15, margin: 0 }}>Mark as complete</p></a>
            </Popconfirm>
          )}    
          {shown && (
            <QRCodeModal shown={shown} setShown={setShown} content={`workshop-${record.id}`} />
          )}
        </span>
      )
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={workshops}
        rowKey="id"
        pagination={false}
      />
      <EditInfoModal />
    </div>
  );
};

export default connect(
  (s: IRootState) => ({
    isShown: s.workshop.editModalIsShown,
    workshops: s.workshop.workshops
  }),
  (dispatch: ArThunkDispatch) => ({
    syncInfoSuccess: (info: WorkshopInfoModel) =>
      dispatch(syncInfoSuccess(info)),
    syncEditModalSuccess: (isShown: boolean) =>
      dispatch(syncEditModalSuccess(isShown)),
    updateStatus: (id: string) => dispatch(updateStatus(id))
  })
)(WorkShopTable);
