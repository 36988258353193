import { Button, Popconfirm, Table, Tag } from "antd";
import * as React from "react";
import { IRootState, ArThunkDispatch } from "../../Store";
import { getAllPhoto, confirmMission } from "../../redux/photo/thunk";
import { connect } from "react-redux";
import {
  userListModel,
  userPhotoModel,
  userMissionResultModel
} from "../../redux/photo/state";
import { PhotoModal } from "./PhotoModal";

const { REACT_APP_API_SERVER } = process.env;
interface dataModal {
  id: number;
  mission_1: string | null;
  mission_2: string | null;
  mission_3: string | null;
  mission_4: string | null;
  mission_5: string | null;
  mission_6: string | null;
  mission_7: string | null;
  mission_8: string | null;
  mission_9: string | null;
  mission_10: string | null;
}
type columnType =
  | "mission_1"
  | "mission_2"
  | "mission_3"
  | "mission_4"
  | "mission_5"
  | "mission_6"
  | "mission_7"
  | "mission_8"
  | "mission_9"
  | "mission_10";
  interface PhotoColumnModel {
    title: string;
    dataIndex: string;
    key: string;
    render?: any;
    sorter?: any;
    sortDirections?: any;
    filters?: any;
    onFilter?: any;
  };

interface Props {
  userList: userListModel[];
  missionResult: userMissionResultModel[];
  photos: userPhotoModel[];
  getAllPhoto: () => void;
  confirmMission: (member_id: string, mission_id: string, status: string) => void;
}
const PhotoTable: React.FC<Props> = props => {
  const {
    userList,
    photos,
    getAllPhoto,
    confirmMission,
    missionResult
  } = props;
  const [pic, setPic] = React.useState<userPhotoModel[]>([]);
  const [list, setList] = React.useState<userListModel[]>([]);
  const [shown, setShown] = React.useState(false);
  const [imgInfo, setImgInfo] = React.useState({ 
    mission_id: 0,
    member_id: 0,
    source: "",
    status: "",
  });

  React.useEffect(() => {
    getAllPhoto();
  }, [getAllPhoto]);

  React.useEffect(() => {
    userList && setList(userList);
  }, [userList]);

  React.useEffect(() => {
    photos && setPic(photos);
  }, [photos]);

  const columns: PhotoColumnModel[] = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "user_id",
      sorter: (a: dataModal, b: dataModal) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
    }
  ];
  const mapPhotoData = (picArr: userPhotoModel[], index: string) => {
    const item = picArr.find(e => e.mission_id === index);
    if (item) {
      return `${REACT_APP_API_SERVER}/${item.photo_name}`;
    } else {
      return null;
    }
  };
  const data = list.map(e => {
    const photos = pic.filter(j => j.user_id === e.id);
    const data = {
      id: e.id,
      mission_1: mapPhotoData(photos, "1"),
      mission_2: mapPhotoData(photos, "2"),
      mission_3: mapPhotoData(photos, "3"),
      mission_4: mapPhotoData(photos, "4"),
      mission_5: mapPhotoData(photos, "5"),
      mission_6: mapPhotoData(photos, "6"),
      mission_7: mapPhotoData(photos, "7"),
      mission_8: mapPhotoData(photos, "8"),
      mission_9: mapPhotoData(photos, "9"),
      mission_10: mapPhotoData(photos, "10")
    };
    return data;
  });

  const siteNames = [
    'Sunny Bay',
    'The Brothers',
    'Tai Ho',
    'Pui O',
    'Cheung Sha',
    'Tong Fuk',
    'Shui Hau',
    'Tung Chung Bay',
    'Sham Wat',
    'Tai O',
  ];
  const renderColumn = () => {
    for (let i = 1; i < 11; i++) {
      let item = {
        title: `Mission ${i}\n${siteNames[i - 1]}`,
        dataIndex: `mission_${i}`,
        key: `mission_${i}`,
        render: (data: string, record: dataModal) => {
          if (data) {
            return (
              <img
                src={`${data}`}
                alt={`img${i}`}
                style={{
                  display: "block",
                  maxWidth: 120,
                  maxHeight: 150,
                  width: "auto",
                  height: "auto",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const result = missionResult.find(e => e.user_id === record.id);
                  const isDone = result ? result[`mission_${i}`] : false;
                  setShown(true);
                  setImgInfo({
                    member_id: record.id,
                    mission_id: i,
                    source: data,
                    status: isDone,
                  });
                }}
              />
            )
          } else {
            return (
              <p>-</p>
            )
          }},
        width: "20",
      };
      columns.push(item);
      let action = {
        title: "Action",
        dataIndex: `mission_${i}`,
        key: `action_${i}`,
        width: "20",
        render: (data: string, record: dataModal) => {
          const cKey: columnType = `mission_${i}` as columnType;
          const result = missionResult.find(e => e.user_id === record.id);
          const status = result ? result[cKey] : null;
          const isApproved = status === 'confirmed';
          if (status) {
            return <Tag color={isApproved ? "green" : "red"}>{status.toUpperCase()}</Tag>;
          } else if (data && !status) {
            return (
              <div style={{ display: "flex", flexDirection: "column", width: "70%"  }}>
                <Popconfirm
                  title="Sure to confirm?"
                  onConfirm={() =>
                    confirmMission(record.id.toString(), `mission_${i}`, 'confirmed')
                  }
                >
                  <Button type="primary" size="small" style={{ marginBottom: "0.5rem" }}>
                    Confirm
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Reject photo?"
                  onConfirm={() =>
                    confirmMission(record.id.toString(), `mission_${i}`, 'rejected')
                  }
                >
                  <Button type="danger" size="small" ghost>
                    Reject
                  </Button>
                </Popconfirm>
              </div>
            );
          } else {
            return (
              <Button type="primary" size="small" disabled>
                Confirm
              </Button>
            );
          }
        },
        filters: [
          {
            text: 'Pending',
            value: 'pending',
          },
          {
            text: 'Confirmed',
            value: 'confirmed',
          },
          {
            text: 'Rejected',
            value: 'rejected',
          },
        ],
        onFilter: (value: string, record: dataModal) => {
          const imageUrl = record[ `mission_${i}`];
          const result = missionResult.find(e => e.user_id === record.id);
          const status = result ? result[`mission_${i}`] : null;

          if (value === 'pending') {
            return imageUrl !== null && status === null;
          }
          return value === status;
        },
      };
      columns.push(action);
    }
  };
  renderColumn();

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={false}
        scroll={{ x: 3000 }}
        style={{ whiteSpace: 'pre'}}
      />
      <PhotoModal
        shown={shown}
        setShown={setShown}
        source={imgInfo.source}
        status={imgInfo.status}
        confirmMission={(status: string) => {
          confirmMission(imgInfo.member_id.toString(), `mission_${imgInfo.mission_id}`, status);
          setImgInfo({
            ...imgInfo,
            status: status,
          });
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  userList: state.photo.userList,
  photos: state.photo.photos,
  missionResult: state.photo.missionResult
});
const mapDispatchToProps = (dispatch: ArThunkDispatch) => ({
  getAllPhoto: () => dispatch(getAllPhoto()),
  confirmMission: (member_id: string, mission_id: string, status: string) =>
    dispatch(confirmMission(member_id, mission_id, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTable);
