import * as React from 'react';
import { IRootState, ArThunkDispatch } from '../../Store';
import { connect } from 'react-redux';
import { workshopTeamInfoModel, WorkshopTeamNameModel } from '../../model';
import { IBoardBadges } from '../../redux/workshop/state';
import { Table, Typography, Button, Icon } from 'antd';
import { updateLeaderInfo, removeTeamUser } from '../../redux/workshop/thunk';
import { socket } from '../../page/GameRoomListPage';
import { validateBoardNo } from './CurrentBadgePosition';
import { WORKSHOP_QUESTIONS } from '../../data/workshop_questions';
const { Title } = Typography;

interface Props {
  currentTeamInfo: workshopTeamInfoModel[];
  teamNames: WorkshopTeamNameModel;
  updateLeaderInfo: (
    member_id: string,
    team_id: string,
    workshop_id: string,
    is_leader?: boolean
  ) => void;
  id: string;
  currentTeamLeader: workshopTeamInfoModel[];
  boardBadges: IBoardBadges;
  removeTeamUser: (
    workshop_id: string,
    member_id: string,
    team_id: string
  ) => void;
}

const questionState = {
  questionId: null,
  isTimerActive: false,
};
const allTeamQs = {
  t1: questionState,
  t2: questionState,
  t3: questionState,
  t4: questionState,
  t5: questionState,
  t6: questionState,
};
const allTeamArr = {
  t1: [],
  t2: [],
  t3: [],
  t4: [],
  t5: [],
  t6: [],
};

const TeamTable: React.FC<Props> = (props) => {
  const {
    currentTeamInfo,
    teamNames,
    updateLeaderInfo,
    id,
    currentTeamLeader,
    boardBadges,
    removeTeamUser,
  } = props;
  const teamIdArr = ['t1', 't2', 't3', 't4', 't5', 't6'];
  const [teamQuestions, setTeamQuestions] = React.useState<any>(allTeamQs);
  const [teamAns, setTeamAns] = React.useState<any>(allTeamArr);

  const arr: string[] = [];
  const teamNameArr = arr
    .concat(teamNames.team_1_name)
    .concat(teamNames.team_2_name)
    .concat(teamNames.team_3_name)
    .concat(teamNames.team_4_name)
    .concat(teamNames.team_5_name)
    .concat(teamNames.team_6_name);
  const columns = [
    { title: 'ID', dataIndex: 'member_id', key: 'member_id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Answer Status',
      key: 'answer',
      render: (data: workshopTeamInfoModel) => {
        const submittedAns = teamAns[data.team_id].find(
          (item) => item.id === data.member_id
        );
        const { isTimerActive } = teamQuestions[data.team_id];

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Icon
              type="clock-circle"
              spin={isTimerActive}
              style={
                isTimerActive
                  ? { fontSize: 20, marginRight: 12, color: '#1890ff' }
                  : { fontSize: 20, marginRight: 12, color: 'gray' }
              }
            />
            <p style={{ fontWeight: 600 }}>
              {submittedAns ? submittedAns.choiceId : ''}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: workshopTeamInfoModel) => {
        const isLeader = currentTeamLeader.find(
          (e) => e.member_id === data.member_id
        );
        return (
          <span>
            {!isLeader ? (
              <Button
                style={{ width: 100 }}
                onClick={() =>
                  updateLeaderInfo(
                    data.member_id.toString(),
                    data.team_id,
                    id,
                    true
                  )
                }
              >
                {'Set Leader'}
              </Button>
            ) : (
              <Button
                style={{ width: 100 }}
                type="primary"
                onClick={() =>
                  updateLeaderInfo(
                    data.member_id.toString(),
                    data.team_id,
                    id,
                    false
                  )
                }
              >
                Leader
              </Button>
            )}
            <Button
              type="dashed"
              onClick={async () => {
                removeTeamUser(id, data.member_id.toString(), data.team_id); // db
                socket.emit(
                  'leaveTeam',
                  id,
                  data.team_id,
                  data.member_id,
                  'admin'
                ); // notify removed user
              }}
            >
              <Icon type="close-circle" theme="twoTone" twoToneColor="red" />
            </Button>
          </span>
        );
      },
    },
  ];

  React.useEffect(() => {
    socket.on(
      'adminTimerChange',
      (teamId: string, memberId: number, status: boolean) => {
        console.log('[socket] adminTimeStart', memberId);

        setTeamQuestions((teamQuestions) => ({
          ...teamQuestions,
          [teamId]: { ...teamQuestions[teamId], isTimerActive: status },
        }));
      }
    );

    // Handle client disconnect
    socket.on(
      'notifyLeaveTeam',
      (wId: string, memberId: number, teamId: string) => {
        console.log('[socket] notifyLeaveTeam from', teamId, memberId);
        if (memberId) {
          removeTeamUser(wId, memberId.toString(), teamId);
        }
      }
    );

    socket.on('adminReceiveQuestion', (teamId, questionId) => {
      console.log('[socket] admin receiveQuestion', teamId, questionId);

      setTeamQuestions((teamQuestions) => ({
        ...teamQuestions,
        [teamId]: { questionId: questionId, isTimerActive: false },
      }));

      setTeamAns((teamAns) => ({
        ...teamAns,
        [teamId]: [],
      }));
    });

    socket.on('adminReceiveAns', (teamId, ans) => {
      console.log('[socket] admin receiveAns', teamId, ans);

      setTeamAns((teamAns) => ({
        ...teamAns,
        [teamId]: teamAns[teamId].concat(ans),
      }));
    });

    return () => {
      socket.emit('adminLeave', id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetQuestion = (teamId: string) => {
    console.log('adminResetQuesiton', teamId);
    socket.emit('adminResetQuesiton', teamId);
    setTeamQuestions((teamQuestions) => ({
      ...teamQuestions,
      [teamId]: questionState,
    }));
    setTeamAns((teamAns) => ({
      ...teamAns,
      [teamId]: [],
    }));
  };

  const getQuestion = (boardId: string, questionId: number) => {
    const questionSet = WORKSHOP_QUESTIONS[boardId];
    if (!questionSet || questionId === null) {
      return;
    }
    const currentQuestion = questionSet.find((q) => q.id === questionId);

    return currentQuestion;
  };

  const renderTable = () => {
    return teamIdArr.map((e, i) => {
      const teamMember = currentTeamInfo.filter((j) => j.team_id === e);
      const teamMemberWithAns = teamMember.map((k) => {
        const ans = teamAns[k.team_id].find((a) => a.id === k.member_id);
        if (ans) {
          return {
            ...k,
            choice_id: ans.choiceId,
          };
        }
        return k;
      });

      const teamName = teamNameArr[i];
      const boardId = boardBadges[i + 1];
      const { questionId } = teamQuestions[e]; // socket
      const showQuestion = boardId && !validateBoardNo(boardId) ? true : false;
      const currentQuestion = getQuestion(boardId, questionId);

      if (teamName) {
        return (
          <div key={`div${i}`} style={{ marginBottom: '3rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Title level={4} style={{ color: '#1890ff' }}>{`Team ${
                i + 1
              }: ${teamName}`}</Title>
            </div>

            {showQuestion && currentQuestion ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h4>{currentQuestion.question}</h4>
                  <Button type="dashed" onClick={() => onResetQuestion(e)}>Reset</Button>
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    paddingTop: '0.5rem',
                    paddingBottom: '1rem',
                  }}
                >
                  {currentQuestion.choices.map((ch) => (
                    <p
                      key={ch.id}
                      style={{
                        color:
                          currentQuestion.answer_id === ch.id
                            ? '#05CE9E'
                            : 'black',
                      }}
                    >
                      {ch.id}. {ch.text}
                    </p>
                  ))}
                </div>
              </>
            ) : null}

            <Table
              columns={columns}
              dataSource={teamMemberWithAns}
              rowKey="name"
              pagination={false}
            />
          </div>
        );
      } else {
        return null;
      }
    });
  };
  return <div>{renderTable()}</div>;
};

const mapStateToProps = (state: IRootState) => ({
  currentTeamInfo: state.workshop.currentTeamInfo,
  teamNames: state.workshop.currentTeamNames,
  id: state.workshop.currentWorkShop,
  currentTeamLeader: state.workshop.currentTeamLeader,
  boardBadges: state.workshop.boardBadges,
});
const mapDispatchToProps = (dispatch: ArThunkDispatch) => ({
  updateLeaderInfo: (
    member_id: string,
    team_id: string,
    workshop_id: string,
    is_leader?: boolean
  ) => dispatch(updateLeaderInfo(member_id, team_id, workshop_id, is_leader)),
  removeTeamUser: (workshop_id: string, member_id: string, team_id: string) =>
    dispatch(removeTeamUser(workshop_id, member_id, team_id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeamTable);
