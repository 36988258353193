import { NewWorkshopFormModel } from "../../model";
import { ArThunkDispatch } from "../../Store";
import { authorizedFetch } from "../fetch";
import {
  createWorkshopSuccess,
  failed,
  getAllWorkshopSuccess,
  getCurrentWorkshopSuccess,
  getCurrentPositionSuccess,
  syncTeamPlacement,
  updateInfoSuccess,
  updateStatusSuccess,
  updateLockStatusSuccess,
  getCurrentTeamNameSuccess,
  getTeamInfoSuccess,
  getTeamLeaderSuccess,
  updateTeamLeaderSuccess,
  removeUserSuccess,
} from "./actions";
import { IBoardBadges } from "./state";
const { REACT_APP_API_SERVER } = process.env;

export function updateLeaderInfo(
  memberId: string,
  teamId: string,
  workshopId: string,
  is_leader?: boolean
) {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const updateType = is_leader ? 'new' : 'remove';
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/leader/${memberId}/${teamId}/${workshopId}/${updateType}`
      );
      const result = await res.json();
      if (result && res.status === 200) {
        dispatch(updateTeamLeaderSuccess(result.data));
      } else {
        dispatch(failed("UPDATE_TEAM_LEADER_FAILED", result.msg));
      }
    } catch (e) {
      dispatch(failed("UPDATE_TEAM_LEADER_FAILED", e + " "));
    }
  };
}

export function getCurrentLeaderInfo(id: string) {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/leaderInfo/${id}`
      );
      const result = await res.json();
      if (result && res.status === 200) {
        dispatch(getTeamLeaderSuccess(result.data));
      } else {
        dispatch(failed("GET_TEAM_LEADER_FAILED", result.msg));
      }
    } catch (e) {
      dispatch(failed("GET_TEAM_LEADER_FAILED", e + ""));
    }
  };
}

export function getCurrentTeamInfo(id: string) {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/team/${id}`
      );
      const result = await res.json();
      if (result && res.status === 200) {
        dispatch(getTeamInfoSuccess(result.data));
      } else {
        dispatch(failed("GET_TEAM_INFO_FAILED", result.msg));
      }
    } catch (e) {
      dispatch(failed("GET_TEAM_INFO_FAILED", e + ""));
    }
  };
}

export function getCurrentTeamName(id: string) {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/teamName/${id}`
      );
      const result = await res.json();
      if (result && res.status === 200) {
        dispatch(getCurrentTeamNameSuccess(result.data));
      } else {
        dispatch(failed("GET_CURRENT_TEAM_NAME_FAILED", result.msg));
      }
    } catch (e) {
      dispatch(failed("GET_CURRENT_TEAM_NAME_FAILED", e + ""));
    }
  };
}
export function getCurrentPosition(id: string) {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/gameboard/${id}`
      );
      const result = await res.json();
      if (result && res.status === 200) {
        dispatch(getCurrentPositionSuccess(result.data));
      } else {
        dispatch(failed("GET_CURRENT_FAILED", result.msg));
      }
    } catch (e) {
      dispatch(failed("GET_CURRENT_FAILED", e + " "));
    }
  };
}
export function updateGameBoard(id: string, badge: IBoardBadges) {
  return async (d: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/gameboard/${id}`,
        {
          method: "POST",
          body: JSON.stringify(badge)
        }
      );
      const result = await res.json();
      if (result && res.status === 200) {
        d(syncTeamPlacement(result.data));
      } else {
        d(failed("SYNC_TEAM_PLACEMENT_FAILED", result.msg));
      }
    } catch (e) {
      d(failed("SYNC_TEAM_PLACEMENT_FAILED", e + ""));
    }
  };
}
export function getAllWorkShop() {
  return async (d: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(`${REACT_APP_API_SERVER}/workshop/`);
      const result = await res.json();
      if (result && res.status === 200 && result.success) {
        d(getAllWorkshopSuccess(result.data));
      } else {
        d(failed("GET_WORKSHOPS_FAILED", result.msg));
      }
    } catch (e) {
      d(failed("GET_WORKSHOPS_FAILED", e + ""));
    }
  };
}
export function getCurrentWorkShop(id: string) {
  return async (d: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(`${REACT_APP_API_SERVER}/workshop/${id}`);
      const result = await res.json();
      
      if (result && res.status === 200) {
        d(getCurrentWorkshopSuccess(result));
      } else {
        d(failed("GET_CURRENT_WORKSHOP_FAILED", result.msg));
      }
    } catch (e) {
      d(failed("GET_CURRENT_WORKSHOP_FAILED", e + ""));
    }
  };
}

export function createWorkshop(form: NewWorkshopFormModel) {
  return async (dispatch: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/new`,
        {
          method: "POST",
          body: JSON.stringify(form)
        }
      );
      const result = await res.json();
      if (result && res.status === 200) {
        dispatch(createWorkshopSuccess(result.successMsg));
      }
    } catch (e) {
      console.error(e);
      dispatch(failed("CREATE_WORKSHOP_FAILED", e + ""));
    }
  };
}

export function updateStatus(id: string) {
  return async (d: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/complete/${id}`
      );
      const result = await res.json();
      if (result && res.status === 200 && result.success === true) {
        d(updateStatusSuccess(result.msg));
      } else {
        d(failed("UPDATE_STATUS_FAILED", result.msg));
      }
    } catch (e) {
      console.error(e);
      d(failed("UPDATE_STATUS_FAILED", e + ""));
    }
  };
}

export function updateLockStatus(id: string) {
  return async (d: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/lock/${id}`,
        {
          method: "POST",
          body: JSON.stringify({ locked: true })
        }
      );
      const result = await res.json();
      if (result && res.status === 200 && result.success === true) {
        d(updateLockStatusSuccess(result.msg));
      } else {
        d(failed("UPDATE_LOCK_STATUS_FAILED", result.msg));
      }
    } catch (e) {
      console.error(e);
      d(failed("UPDATE_LOCK_STATUS_FAILED", e + ""));
    }
  };
}

export function updateWorkshop(id: string, form: NewWorkshopFormModel) {
  return async (d: ArThunkDispatch) => {
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/update/${id}`,
        {
          method: "POST",
          body: JSON.stringify(form)
        }
      );
      const result = await res.json();
      if (result && res.status === 200 && result.success === true) {
        d(updateInfoSuccess(result.msg));
      } else {
        d(failed("UPDATE_WORKSHOP_FAILED", result.msg));
      }
    } catch (e) {
      console.error(e);
      d(failed("UPDATE_WORKSHOP_FAILED", e + ""));
    }
  };
}

export function removeTeamUser(id: string, member_id: string, team_id: string) {
  return async (d: ArThunkDispatch) => {
    console.log({ id, member_id, team_id });
    
    try {
      const res = await authorizedFetch(
        `${REACT_APP_API_SERVER}/workshop/leave/${id}`,
        {
          method: "POST",
          body: JSON.stringify({ member_id: member_id, team_id: team_id })
        }
      );
      const result = await res.json();
      console.log(result);

      if (result && res.status === 200 && result.success === true) {
        d(removeUserSuccess(result.msg));
      } else {
        d(failed("REMOVE_USER_FAILED", result.msg));
      }
    } catch (e) {
      console.error(e);
      d(failed("REMOVE_USER_FAILED", e + ""));
    }
  };
}
