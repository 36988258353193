import { ConnectedRouter } from "connected-react-router";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import WrappedHorizontalLoginForm from "./components/Login";
import { Portal } from "./page/Portal";
import { createArStore, history } from "./Store";
import "./App.less";
import GameBoard from "./page/GameBoard";

const store = createArStore();
class App extends Component {
  public render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="App">
            <Switch>
              <Route path="/workshop/:id" component={GameBoard} />
              <Route path="/portal/:type" component={Portal} />
              <Route path="/" component={WrappedHorizontalLoginForm} />
            </Switch>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
