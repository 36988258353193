import { Col, Row, message } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import CurrentBadgePosition from '../components/game-page/CurrentBadgePosition';
import GameBoardControlFrom from '../components/game-page/GameBadgeControl';
import TeamTable from '../components/game-page/TeamTable';
import LockControl from '../components/game-page/LockControl';
import { workshopTeamInfoModel } from '../model';
import {
  getTeamInfoSuccess,
  syncCurrentWorkshop,
  clearMessageSuccess,
} from '../redux/workshop/actions';
import {
  getCurrentLeaderInfo,
  getCurrentPosition,
  getCurrentTeamInfo,
  getCurrentTeamName,
  getCurrentWorkShop,
} from '../redux/workshop/thunk';
import { ArThunkDispatch, IRootState } from '../Store';
import { socket } from './GameRoomListPage';

interface Props {
  match: match<{ id: string }>;
  syncCurrentWorkshop: (id: string) => void;
  getCurrentPosition: (id: string) => void;
  getCurrentTeamName: (id: string) => void;
  getCurrentTeamInfo: (id: string) => void;
  getTeamInfoSuccess: (teamInfo: workshopTeamInfoModel[]) => void;
  getCurrentLeaderInfo: (id: string) => void;
  getCurrentWorkShop: (id: string) => void;
  updateLockStatusMsg: string;
  clearMessageSuccess: () => void;
}

const GamePage: React.FC<Props> = (props) => {
  const {
    match,
    syncCurrentWorkshop,
    getCurrentPosition,
    getCurrentTeamName,
    getCurrentTeamInfo,
    getTeamInfoSuccess,
    getCurrentLeaderInfo,
    getCurrentWorkShop,
    updateLockStatusMsg,
    clearMessageSuccess,
  } = props;

  React.useEffect(() => {
    if (updateLockStatusMsg) {
      message.success(`${updateLockStatusMsg}`);
    }
    return () => clearMessageSuccess();
  }, [updateLockStatusMsg, clearMessageSuccess]);

  React.useEffect(() => {
    const id = match.params.id;
    if (id) {
      syncCurrentWorkshop(id);
      socket.emit('adminJoin', id);

      getCurrentWorkShop(id);
      getCurrentTeamName(id);
      getCurrentPosition(id);
      getCurrentTeamInfo(id);
      getCurrentLeaderInfo(id);

      socket.on('teamInfo', (data: any) => {
        console.log('[socket] teamInfo', data);
        getTeamInfoSuccess(data);
      });
      return () => {
        socket.emit('adminLeave', id);
      };
    }
  }, [
    match.params.id,
    syncCurrentWorkshop,
    getCurrentPosition,
    getCurrentTeamName,
    getCurrentTeamInfo,
    getTeamInfoSuccess,
    getCurrentLeaderInfo,
    getCurrentWorkShop,
  ]);

  React.useEffect(() => {
    const id = match.params.id;
    if (id) {
      socket.on('adminUpdateTeamName', (info) => {
        console.log('[socket] admin UpdateTeamName', info);
        getCurrentTeamName(id);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id])

  return (
    <div style={{ margin: '1rem' }}>
      <Link to="/portal/game">Back</Link>
      <Row type="flex" justify="end">
        <LockControl />
      </Row>

      <Row type="flex" style={{ marginTop: '2rem' }}>
        <Col span={5}>
          <CurrentBadgePosition showQR />
        </Col>
        <Col span={5}>
          <GameBoardControlFrom />
        </Col>
        <Col span={12}>
          <TeamTable />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  updateLockStatusMsg: state.workshop.updateLockStatusMsg,
});
const mapDispatchToProps = (dis: ArThunkDispatch) => ({
  syncCurrentWorkshop: (id: string) => dis(syncCurrentWorkshop(id)),
  getCurrentPosition: (id: string) => dis(getCurrentPosition(id)),
  getCurrentTeamName: (id: string) => dis(getCurrentTeamName(id)),
  getCurrentTeamInfo: (id: string) => dis(getCurrentTeamInfo(id)),
  getTeamInfoSuccess: (teamInfo: workshopTeamInfoModel[]) =>
    dis(getTeamInfoSuccess(teamInfo)),
  getCurrentLeaderInfo: (id: string) => dis(getCurrentLeaderInfo(id)),
  getCurrentWorkShop: (id: string) => dis(getCurrentWorkShop(id)),
  clearMessageSuccess: () => dis(clearMessageSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GamePage);
