import {
  connectRouter,
  RouterAction,
  routerMiddleware,
  RouterState
} from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk, { ThunkDispatch } from "redux-thunk";
import { IAuthActions } from "./redux/auth/actions";
import { authReducer } from "./redux/auth/reducer";
import { IAuthStates } from "./redux/auth/state";
import { restoreLogin } from "./redux/auth/thunk";
import { IWorkshopActions } from "./redux/workshop/actions";
import { workshopReducer } from "./redux/workshop/reducer";
import { IWorkshopStates } from "./redux/workshop/state";
import { IPhotoStates } from "./redux/photo/state";
import { IPhotoActions } from "./redux/photo/action";
import { photoReducer } from "./redux/photo/reducer";

export const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

export interface IRootState {
  auth: IAuthStates;
  photo: IPhotoStates;
  workshop: IWorkshopStates;
  router: RouterState;
}

export type IRootActions =
  | RouterAction
  | IAuthActions
  | IWorkshopActions
  | IPhotoActions;

export type ArThunkDispatch = ThunkDispatch<IRootState, null, IRootActions>;

const rootReducer = combineReducers<IRootState>({
  auth: authReducer,
  workshop: workshopReducer,
  photo: photoReducer,
  router: connectRouter(history)
});

const logger = createLogger({});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function createArStore(preloadedState?: IRootState) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(logger),
      applyMiddleware(thunk),
      applyMiddleware(routerMiddleware(history))
    )
  );

  const dispatch: ArThunkDispatch = store.dispatch;
  dispatch(restoreLogin());
  return store;
}
