import * as React from "react";
import { Modal } from "antd";
import WrappedEditWorkshopForm from "./EditWorkshopInfoForm";
import { connect } from "react-redux";
import { IRootState, ArThunkDispatch } from "../../Store";
import { syncEditModalSuccess } from "../../redux/workshop/actions";

interface Props {
  isShown: boolean;
  syncEditModalSuccess: (isShown: boolean) => void;
}

const EditWorkshopModal: React.FC<Props> = props => {
  const { isShown, syncEditModalSuccess } = props;

  const handleCancel = () => {
    syncEditModalSuccess(false);
  };

  return (
    <Modal
      title="Edit Workshop"
      visible={isShown}
      onCancel={handleCancel}
      footer={null}
    >
      <WrappedEditWorkshopForm />
    </Modal>
  );
};

export default connect(
  (state: IRootState) => ({
    isShown: state.workshop.editModalIsShown
  }),
  (d: ArThunkDispatch) => ({
    syncEditModalSuccess: (isShown: boolean) => d(syncEditModalSuccess(isShown))
  })
)(EditWorkshopModal);
