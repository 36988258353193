const SUNNY_BAY_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 2,
    question: '欣澳的舊名是?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '恩澳',
      },
      {
        id: 'B',
        text: '陰澳',
      },
      {
        id: 'C',
        text: '貝澳',
      },
      {
        id: 'D',
        text: '殷澳',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 2,
    question: '為什麼要改名為欣澳?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '因為欣澳這名字較能代表該地的地理位置',
      },
      {
        id: 'B',
        text: '因為當地居民認為改名欣澳能帶來好運',
      },
      {
        id: 'C',
        text: '因為要配合迪士尼樂園的歡樂形象',
      },
      {
        id: 'D',
        text: '沒有特別理由',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 2,
    question: '以下哪一個是欣澳的著名景點?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '廸士尼樂園',
      },
      {
        id: 'B',
        text: '鹿頸碼頭',
      },
      {
        id: 'C',
        text: '陰澳灣的公眾廁所',
      },
      {
        id: 'D',
        text: '陰澳灣的木塘',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 2,
    question: '甚麼是木塘?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '木造的池塘',
      },
      {
        id: 'B',
        text: '貯存木材的海灣',
      },
      {
        id: 'C',
        text: '長滿樹木的水塘',
      },
      {
        id: 'D',
        text: '以上皆不是',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 2,
    question: '欣澳的舊名「陰澳」的「陰」字代表甚麼?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '陰澳位於山的北面',
      },
      {
        id: 'B',
        text: '陰澳多陰天',
      },
      {
        id: 'C',
        text: '陰澳有陰暗的歷史',
      },
      {
        id: 'D',
        text: '跟鬼故事有關',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 2,
    question: '欣澳位於大嶼山的',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '東南面',
      },
      {
        id: 'B',
        text: '東面',
      },
      {
        id: 'C',
        text: '西北面',
      },
      {
        id: 'D',
        text: '東北面',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 2,
    question: '欣澳海旁的防波堤是什麼海岸生境?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '淡水濕地',
      },
      {
        id: 'B',
        text: '泥灘',
      },
      {
        id: 'C',
        text: '石灘',
      },
      {
        id: 'D',
        text: '紅樹林',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 2,
    question: '欣澳港鐵站是在香港十八區區域劃分上為',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '屯門區',
      },
      {
        id: 'B',
        text: '荃灣區',
      },
      {
        id: 'C',
        text: '元朗區',
      },
      {
        id: 'D',
        text: '離島區',
      },
    ],
  },
];

const THE_BROTHERS_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 3,
    question: '大小磨刀海岸公園於哪年被指定為海岸公園?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '1968',
      },
      {
        id: 'B',
        text: '1997',
      },
      {
        id: 'C',
        text: '2002',
      },
      {
        id: 'D',
        text: '2016',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 3,
    question: '大小磨刀海岸公園是為了保育以下哪一種動物而成立的?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '江豚',
      },
      {
        id: 'B',
        text: '中華白海豚',
      },
      {
        id: 'C',
        text: '珊瑚',
      },
      {
        id: 'D',
        text: '小丑魚',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 3,
    question: '中華白海豚剛出生時的顏色是?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '深藍',
      },
      {
        id: 'B',
        text: '深灰',
      },
      {
        id: 'C',
        text: '白',
      },
      {
        id: 'D',
        text: '粉紅',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 3,
    question: '中華白海豚成年時的顏色是?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '深藍',
      },
      {
        id: 'B',
        text: '深灰',
      },
      {
        id: 'C',
        text: '白',
      },
      {
        id: 'D',
        text: '粉紅',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 3,
    question: '中華白海豚是什麼動物?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '哺乳類',
      },
      {
        id: 'B',
        text: '魚類',
      },
      {
        id: 'C',
        text: '兩棲類',
      },
      {
        id: 'D',
        text: '軟體動物',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 3,
    question: '中華白海豚目前受以下哪種威脅?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '海水污染',
      },
      {
        id: 'B',
        text: '大型基建工程',
      },
      {
        id: 'C',
        text: '繁忙的海上交通',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 3,
    question: '大小磨刀海岸公園的海洋環境受哪條河流影響?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '東涌河',
      },
      {
        id: 'B',
        text: '珠江河',
      },
      {
        id: 'C',
        text: '貝澳河',
      },
      {
        id: 'D',
        text: '大蠔河',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 3,
    question: '根據2019年漁護署海洋哺乳類動物監察報告，現時香港水域有中華白海豚多少條?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '10 餘條',
      },
      {
        id: 'B',
        text: '30 餘條',
      },
      {
        id: 'C',
        text: '100 餘條',
      },
      {
        id: 'D',
        text: '200 餘條',
      },
    ],
  },
];

const AIRPORT_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 9,
    question: '香港國際機場位於哪裡?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '赤鱲角',
      },
      {
        id: 'B',
        text: '東涌',
      },
      {
        id: 'C',
        text: '昂坪',
      },
      {
        id: 'D',
        text: '石門甲',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 9,
    question: '興建新機場前﹐香港使用的舊機場是?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '桃園機場',
      },
      {
        id: 'B',
        text: '啟德機場',
      },
      {
        id: 'C',
        text: '珀斯機場',
      },
      {
        id: 'D',
        text: '寶安機場',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 9,
    question: '以下哪一種生物因為興建新機場而被搬遷?',
    answer_id: '',
    choices: [
      {
        id: 'A',
        text: '赤鱲',
      },
      {
        id: 'B',
        text: '盧氏小樹蛙',
      },
      {
        id: 'C',
        text: '中華白海豚',
      },
      {
        id: 'D',
        text: '蝦虎魚',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 9,
    question: '興建第三條跑道所引起的爭議是?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '填海工程及繁忙的交通將嚴重威脅中華白海豚的生存',
      },
      {
        id: 'B',
        text: '香港與深圳的領空重疊',
      },
      {
        id: 'C',
        text: '附近水域受嚴重破壞及污染',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 9,
    question: '赤鱲角地名的由來是?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '那裡的石頭是紅色的',
      },
      {
        id: 'B',
        text: '那裡盛產蠟燭﹐而「蠟」跟「鱲」同音',
      },
      {
        id: 'C',
        text: '附近海域曾經盛產赤鱲魚',
      },
      {
        id: 'D',
        text: '以上皆不是',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 9,
    question: '香港國際機場現時有多少條跑道？',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '一條',
      },
      {
        id: 'B',
        text: '二條',
      },
      {
        id: 'C',
        text: '三條',
      },
      {
        id: 'D',
        text: '四條',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 9,
    question: '香港國際機場於哪一年正式啟用？',
    answer_id: '',
    choices: [
      {
        id: 'A',
        text: '1996年',
      },
      {
        id: 'B',
        text: '1997年',
      },
      {
        id: 'C',
        text: '1998年',
      },
      {
        id: 'D',
        text: '2000年',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 9,
    question: '香港國際機場建於人工島上。',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '是',
      },
      {
        id: 'B',
        text: '否',
      },
    ],
  },
];

const DISCOVERY_BAY_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 13,
    question: '興建人工島需要大型填海工程﹐以下哪一項不是大型填海工程帶來的影響?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '天然海灣縮小及消失',
      },
      {
        id: 'B',
        text: '拉直了海岸線，令海水流動速度變急',
      },
      {
        id: 'C',
        text: '破壞生態﹐污染水質',
      },
      {
        id: 'D',
        text: '以上皆是大型填海工程帶來的影響',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 13,
    question: '進行填海需要符合以下哪個原則?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '有迫切及凌駕性的當前需要（包括社群的經濟、環境和社會需要）',
      },
      {
        id: 'B',
        text: '沒有其他可行方法',
      },
      {
        id: 'C',
        text: '對海港的損害減至最少',
      },
      {
        id: 'D',
        text: '以上皆需要符合',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 13,
    question: '以下哪個是將天然海岸填海變成人工海岸的影響?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '失去天然海床的坡度﹐潮間帶消失',
      },
      {
        id: 'B',
        text: '海岸變美觀吸引更多生物',
      },
      {
        id: 'C',
        text: '風水更好',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 13,
    question: `填海方法分為浚挖式及不浚挖式兩種﹐人工島所採用的是不浚挖式填海。請將以下不浚挖式填海的過程排出正確順序。
    (1) 以惰性建築廢料或砂料回填鋼圓筒內
    (2) 建造大直徑的鋼圓筒
    (3) 把鋼圓筒壓下海床，穿越淤泥層`,
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '1→ 2 → 3',
      },
      {
        id: 'B',
        text: '2 → 1 → 3',
      },
      {
        id: 'C',
        text: '3 → 1 → 2',
      },
      {
        id: 'D',
        text: '2 → 3 → 1',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 13,
    question: '截至2016年，香港約有多少公頃的土地是透過填海取得的?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '1000',
      },
      {
        id: 'B',
        text: '4500',
      },
      {
        id: 'C',
        text: '7000',
      },
      {
        id: 'D',
        text: '9000',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 13,
    question: '愉景灣對出的小島是',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '長洲',
      },
      {
        id: 'B',
        text: '坪洲',
      },
      {
        id: 'C',
        text: '喜靈洲',
      },
      {
        id: 'D',
        text: '石鼓洲',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 13,
    question: '以下哪種海岸地區能在愉景灣找到?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '沙灘',
      },
      {
        id: 'B',
        text: '紅樹林',
      },
      {
        id: 'C',
        text: '沼澤濕地',
      },
      {
        id: 'D',
        text: '河溪',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 13,
    question: '彈塗魚是屬於哪類動物?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '兩棲類',
      },
      {
        id: 'B',
        text: '哺乳類',
      },
      {
        id: 'C',
        text: '爬蟲類',
      },
      {
        id: 'D',
        text: '魚類',
      },
    ],
  },
];

const TAI_HO_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 14,
    question: '大蠔在新自然保育政策下被鑒定為12個優先加強保育的地點中排名第幾?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '3',
      },
      {
        id: 'B',
        text: '3',
      },
      {
        id: 'C',
        text: '10',
      },
      {
        id: 'D',
        text: '12',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 14,
    question: '大蠔河及大蠔灣於哪一年被劃為「具特殊科學價值地點」?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '1980',
      },
      {
        id: 'B',
        text: '1995',
      },
      {
        id: 'C',
        text: '1999',
      },
      {
        id: 'D',
        text: '2010',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 14,
    question: '在大蠔河可以找到大約多少種淡水魚?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '10',
      },
      {
        id: 'B',
        text: '30',
      },
      {
        id: 'C',
        text: '50',
      },
      {
        id: 'D',
        text: '70',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 14,
    question: '大蠔河可以找到大約多少種兩棲類?',
    answer_id: '',
    choices: [
      {
        id: 'A',
        text: '5',
      },
      {
        id: 'B',
        text: '10',
      },
      {
        id: 'C',
        text: '15',
      },
      {
        id: 'D',
        text: '20',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 14,
    question: '盧氏小樹蛙被《世界自然保護聯盟紅皮書》列為?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '無危',
      },
      {
        id: 'B',
        text: '近危',
      },
      {
        id: 'C',
        text: '易危',
      },
      {
        id: 'D',
        text: '瀕危',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 14,
    question: '其中一種受保育關注的物種「北江光唇魚」可以於大嶼山什麼地方找到？',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '大蠔河溪',
      },
      {
        id: 'B',
        text: '水口沙坪',
      },
      {
        id: 'C',
        text: '貝澳濕地',
      },
      {
        id: 'D',
        text: '大澳紅樹林',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 14,
    question: '盧氏小樹蛙的身體特徵是',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '背部有「A」形的花紋',
      },
      {
        id: 'B',
        text: '背部有「X」形的花紋',
      },
      {
        id: 'C',
        text: '背部有「C」形的花紋',
      },
      {
        id: 'D',
        text: '背部有「Z」形的花紋',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 14,
    question:
      '大蠔河溪擁有多種不同的生境，孕育多種淡水魚（包括稀有的香魚）。以下哪種行為對保育本地淡水魚是正確的？',
    answer_id: '',
    choices: [
      {
        id: 'A',
        text: '捕捉牠們回家繁殖',
      },
      {
        id: 'B',
        text: '發展河道避免淤塞',
      },
      {
        id: 'C',
        text: '保護現有生境、減少污染及破壞',
      },
      {
        id: 'D',
        text: '放生龜類，增加生物多樣性',
      },
    ],
  },
];

const SHAM_WAT_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 20,
    question: '深屈位於大嶼山的',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '西北面',
      },
      {
        id: 'B',
        text: '東北面',
      },
      {
        id: 'C',
        text: '西南面',
      },
      {
        id: 'D',
        text: '東南面',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 20,
    question: '深屈可以找到以下哪種自然生境?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '紅樹林',
      },
      {
        id: 'B',
        text: '石灘',
      },
      {
        id: 'C',
        text: '河流溪澗',
      },
      {
        id: 'D',
        text: '以上皆有',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 20,
    question: '在深屈的泥灘能找到有什麼動物?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '長趾蛙',
      },
      {
        id: 'B',
        text: '大壁虎',
      },
      {
        id: 'C',
        text: '北江光唇魚',
      },
      {
        id: 'D',
        text: '招潮蟹',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 20,
    question: '以下哪幾個大嶼山地點可以找到紅樹林? 東涌、䃟頭、水口、深屈、大澳',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '東涌、深屈、大澳',
      },
      {
        id: 'B',
        text: '東涌、䃟頭、深屈',
      },
      {
        id: 'C',
        text: '東涌、水口、深屈、大澳',
      },
      {
        id: 'D',
        text: '東涌、䃟頭、水口、深屈、大澳',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 20,
    question: '在深屈能找到多少種蜻蜓?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '15 種',
      },
      {
        id: 'B',
        text: '23 種',
      },
      {
        id: 'C',
        text: '31 種',
      },
      {
        id: 'D',
        text: '51 種',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 20,
    question: '在香港紅樹林能找到多少種招潮蟹?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '3種',
      },
      {
        id: 'B',
        text: '4種',
      },
      {
        id: 'C',
        text: '5種',
      },
      {
        id: 'D',
        text: '6種',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 20,
    question: '生長在紅樹林的紅樹怎樣減少或避免吸收水中的鹽分?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '葉面有鹽腺排走鹽分，變成鹽粒',
      },
      {
        id: 'B',
        text: '緩慢生長而減少吸入鹽分',
      },
      {
        id: 'C',
        text: '氣根表面有鹽腺排走泥土中的鹽分',
      },
      {
        id: 'D',
        text: '附在紅樹母體的胚軸會脫落，因而排走鹽分',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 20,
    question: '以下哪種動物並不會在河流溪澗找到?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '廣東米蝦',
      },
      {
        id: 'B',
        text: '蜻蜓',
      },
      {
        id: 'C',
        text: '招潮蟹',
      },
      {
        id: 'D',
        text: '北江光唇魚',
      },
    ],
  },
];

const TUNG_CHUNG_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 21,
    question: '漁農自然護理署在東涌河錄得多少種淡水魚?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '15',
      },
      {
        id: 'B',
        text: '17',
      },
      {
        id: 'C',
        text: '23',
      },
      {
        id: 'D',
        text: '43',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 21,
    question: '以下哪種魚被漁護署內部評估為受保育關注的河溪物種?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '金魚',
      },
      {
        id: 'B',
        text: '紅衫魚',
      },
      {
        id: 'C',
        text: '北江光唇魚',
      },
      {
        id: 'D',
        text: '中華白海豚',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 21,
    question: '東涌河鄰近的䃟頭的海草床主要能發現哪兩種海草?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '川蔓藻和矮大葉藻',
      },
      {
        id: 'B',
        text: '喜鹽草和川蔓藻',
      },
      {
        id: 'C',
        text: '喜鹽草和貝克喜鹽草',
      },
      {
        id: 'D',
        text: '喜鹽草和矮大葉藻',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 21,
    question: '東涌灣附近海域是哪種物種的重要繁殖及棲息地?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '中國鱟',
      },
      {
        id: 'B',
        text: '水牛',
      },
      {
        id: 'C',
        text: '裳鳳蝶',
      },
      {
        id: 'D',
        text: '虎紋蛙',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 21,
    question: `以下哪項是海草床的作用?
    (1) 提供孵育場所給魚蝦
    (2) 提供成魚覓食的地方
    (3) 光合作用吸收二氧化碳
    (4) 作為天然屏障保護海岸免於直接受侵蝕
    `,
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '2, 3',
      },
      {
        id: 'B',
        text: '1, 3',
      },
      {
        id: 'C',
        text: '1, 2, 3',
      },
      {
        id: 'D',
        text: '1, 2, 3, 4',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 21,
    question: '「紅樹林」名字的由來?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '秋天的時候大部份的樹葉會變成紅色',
      },
      {
        id: 'B',
        text: '木欖的花萼含有很高丹寧而變紅',
      },
      {
        id: 'C',
        text: '木欖的胚軸含有很高丹寧而變紅',
      },
      {
        id: 'D',
        text: '紅樹吸引大量色彩繽紛的季候鳥覓食',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 21,
    question: '以下哪種活動可能會破壞海岸生態環境?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '掘蜆',
      },
      {
        id: 'B',
        text: '亂拋垃圾',
      },
      {
        id: 'C',
        text: '把生物帶回家觀賞',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 21,
    question: '一般海洋塑膠垃圾需要多少年才可完全分解?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '10年',
      },
      {
        id: 'B',
        text: '150年',
      },
      {
        id: 'C',
        text: '300年',
      },
      {
        id: 'D',
        text: '450年',
      },
    ],
  },
];

const MUI_WO_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 25,
    question: '梅窩保留了不少舊建築﹐當中包括2個較完整的更樓。更樓的作用是什麼?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '即現時的睡房﹐睡覺的地方',
      },
      {
        id: 'B',
        text: '供村民開會議的地方',
      },
      {
        id: 'C',
        text: '供村民監察、防範敵人入侵',
      },
      {
        id: 'D',
        text: '倉庫',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 25,
    question: '梅窩銀礦洞有過百隻甚麼生物在棲息?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '短吻果蝠',
      },
      {
        id: 'B',
        text: '青竹蛇',
      },
      {
        id: 'C',
        text: '長趾蛙',
      },
      {
        id: 'D',
        text: '金腳帶',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 25,
    question: '遇上牛時﹐應該怎麼做?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '細心觀察',
      },
      {
        id: 'B',
        text: '保持冷靜',
      },
      {
        id: 'C',
        text: '不要喧嘩',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 25,
    question: '梅窩位於大嶼山的',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '東北面',
      },
      {
        id: 'B',
        text: '東南面',
      },
      {
        id: 'C',
        text: '西南面',
      },
      {
        id: 'D',
        text: '西北面',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 25,
    question: '如何分辨水牛及黃牛?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '黃牛的角較水牛的短',
      },
      {
        id: 'B',
        text: '黃牛的角不會彎曲; 水牛的角是彎曲的',
      },
      {
        id: 'C',
        text: '黃牛偏黃色; 水牛偏黑色',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 25,
    question: '雄性招潮蟹的大鉗有什麼功用?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '呼喚潮水',
      },
      {
        id: 'B',
        text: '吸引異性',
      },
      {
        id: 'C',
        text: '覓食',
      },
      {
        id: 'D',
        text: '挖掘泥土',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 25,
    question: '馬碲蟹的近親是',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '蟹',
      },
      {
        id: 'B',
        text: '蝦',
      },
      {
        id: 'C',
        text: '馬',
      },
      {
        id: 'D',
        text: '蜘蛛和蠍子',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 25,
    question: '梅窩銀鑛灣名字的由來',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '因為銀鑛灣的沙是銀色的',
      },
      {
        id: 'B',
        text: '因為銀鑛灣以出產銀鑛為名',
      },
      {
        id: 'C',
        text: '因為銀鑛灣有很多銀葉樹',
      },
      {
        id: 'D',
        text: '因為銀鑛灣以出產銀色閃爍的貝殼為主',
      },
    ],
  },
];

const TAI_O_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 21,
    question: '在碼頭紅樹林能找到哪種真紅樹?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '秋茄',
      },
      {
        id: 'B',
        text: '銀葉樹',
      },
      {
        id: 'C',
        text: '欖李',
      },
      {
        id: 'D',
        text: '海芒果',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 21,
    question: '以下哪項是大彈塗魚的特徵?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '佈滿紅色螢光斑點',
      },
      {
        id: 'B',
        text: '眼睛長在頭部的兩邊',
      },
      {
        id: 'C',
        text: '體型比香港其他彈塗魚細小',
      },
      {
        id: 'D',
        text: '有特化的胸鰭',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 21,
    question: '蘆葦林有甚麼重要性?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '為昆蟲提供棲息地',
      },
      {
        id: 'B',
        text: '為雀鳥提供棲息地',
      },
      {
        id: 'C',
        text: '過濾水中污染物',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 21,
    question: '以下哪種是大彈塗魚的食物?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '藻類',
      },
      {
        id: 'B',
        text: '小魚',
      },
      {
        id: 'C',
        text: '招潮蟹',
      },
      {
        id: 'D',
        text: '蜆',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 21,
    question: '大澳位於大嶼山的?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '東面',
      },
      {
        id: 'B',
        text: '南面',
      },
      {
        id: 'C',
        text: '西面',
      },
      {
        id: 'D',
        text: '北面',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 21,
    question: '大澳大涌橋會於什麼節日打開?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '中秋節',
      },
      {
        id: 'B',
        text: '聖誕節',
      },
      {
        id: 'C',
        text: '端午節',
      },
      {
        id: 'D',
        text: '農曆新年',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 21,
    question: '大澳以前是一個重要的',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '轉口港',
      },
      {
        id: 'B',
        text: '漁港',
      },
      {
        id: 'C',
        text: '避風港',
      },
      {
        id: 'D',
        text: '以上皆不是',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 21,
    question: '大澳虎山觀景台除了觀看日落及港珠澳大橋外，還有可以欣賞',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '江豚',
      },
      {
        id: 'B',
        text: '珊瑚礁',
      },
      {
        id: 'C',
        text: '中華白海豚',
      },
      {
        id: 'D',
        text: '馬碲蟹',
      },
    ],
  },
];

const PUI_O_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 21,
    question: '根據《大嶼山南岸分區計劃大綱核准圖編號S/SLC/21》，貝澳屬於以下哪種保護區?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '海岸保護區',
      },
      {
        id: 'B',
        text: '海洋保護區',
      },
      {
        id: 'C',
        text: '濕地保護區',
      },
      {
        id: 'D',
        text: '河川保護區',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 21,
    question: '貝澳的另一個名稱是?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '不澳',
      },
      {
        id: 'B',
        text: '杯澳',
      },
      {
        id: 'C',
        text: '欣澳',
      },
      {
        id: 'D',
        text: '陰澳',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 21,
    question: '以下哪種動物會幫助荒廢的農田變成淡水濕地?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '長趾蛙',
      },
      {
        id: 'B',
        text: '虎紋蛙',
      },
      {
        id: 'C',
        text: '水牛',
      },
      {
        id: 'D',
        text: '牛背鷺',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 21,
    question: '下哪一項不是有關虎紋蛙的正確描述?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '虎紋蛙即是田雞',
      },
      {
        id: 'B',
        text: '虎紋蛙棲息在水流湍急的河溪',
      },
      {
        id: 'C',
        text: '野生的虎紋蛙是橄欖綠色，有深色斑點的',
      },
      {
        id: 'D',
        text: '放生人工繁殖的田雞會危害野生田雞',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 21,
    question: '以下哪一項不是有關水牛的正確描述?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '會幫助荒廢的農田變成淡水濕地',
      },
      {
        id: 'B',
        text: '是全港最大的陸地哺乳濕地動物',
      },
      {
        id: 'C',
        text: '身邊經常有牛背鷺相伴',
      },
      {
        id: 'D',
        text:
          '大嶼山的水牛生態已在2019年獲國際古蹟遺址理事會（ICOMOS）承認為「人文價值景觀遺產」',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 21,
    question: '以下哪一項是有關貝澳河的正確描述?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '貝澳河整段溪流都被漁農自然護理署劃分為「具重要生態價值河溪」',
      },
      {
        id: 'B',
        text: '只能找到水生動物',
      },
      {
        id: 'C',
        text: '能找到子陵吻鰕虎魚',
      },
      {
        id: 'D',
        text: '不能找到尖頭塘鱧',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 21,
    question: '貝澳擁有多元化生境，如風水林、沼澤、還有',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '石灘和河溪',
      },
      {
        id: 'B',
        text: '河溪和紅樹林',
      },
      {
        id: 'C',
        text: '沙灘和森林',
      },
      {
        id: 'D',
        text: '草地和沙坪',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 21,
    question: '貝澳位於大嶼山的',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '東北面',
      },
      {
        id: 'B',
        text: '東面',
      },
      {
        id: 'C',
        text: '南面',
      },
      {
        id: 'D',
        text: '西南面',
      },
    ],
  },
];

const CHEUNG_SHA_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 21,
    question: '長沙位於大嶼山的?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '東岸',
      },
      {
        id: 'B',
        text: '南岸',
      },
      {
        id: 'C',
        text: '西岸',
      },
      {
        id: 'D',
        text: '西岸',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 21,
    question: '長沙為什麼叫長沙?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '因為有陝長的沙灘',
      },
      {
        id: 'B',
        text: '因為那裡的沙是長條型的',
      },
      {
        id: 'C',
        text: '因為長沙寓意長壽',
      },
      {
        id: 'D',
        text: '沒有原因',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 21,
    question: '以下哪一個物種受到第170章《野生動物保護條例》保護？',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '文蛤',
      },
      {
        id: 'B',
        text: '馬碲蟹',
      },
      {
        id: 'C',
        text: '紅衫魚',
      },
      {
        id: 'D',
        text: '基圍蝦',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 21,
    question: '掘蜆是一項非法的活動。',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '是',
      },
      {
        id: 'B',
        text: '否',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 21,
    question: '紅樹林的紅樹怎樣適應泥土低氧的環境？',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '生長胚軸吸收水中的氧分',
      },
      {
        id: 'B',
        text: '葉子比一般陸地植物為厚',
      },
      {
        id: 'C',
        text: '於泥土生出氣根吸收空氣中的氧分',
      },
      {
        id: 'D',
        text: '板根抓緊泥土中的氧分',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 21,
    question: '什麼是生物多樣性？',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '於特定地方只有植物',
      },
      {
        id: 'B',
        text: '於特定地方有很多某種生物',
      },
      {
        id: 'C',
        text: '於特定地方有一兩種生物',
      },
      {
        id: 'D',
        text: '於特定地方有很多不同生物',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 21,
    question: '潮退時，紅樹林的彈塗魚會',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '於泥土中走出來覓食',
      },
      {
        id: 'B',
        text: '躲藏於泥土中',
      },
      {
        id: 'C',
        text: '於泥土中走出來吸收陽光',
      },
      {
        id: 'D',
        text: '覓找伴侶',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 21,
    question: '以下哪個因素令到生物難於在沙灘生存？',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '沙礫沒有水分',
      },
      {
        id: 'B',
        text: '太多人於沙灘活動',
      },
      {
        id: 'C',
        text: '潮汐漲退',
      },
      {
        id: 'D',
        text: '水質污染',
      },
    ],
  },
];

const SHUI_HAU_QUESTIONS = [
  {
    status: 'new',
    id: 1,
    board_no: 21,
    question: '水口沙坪是以下哪種生物的重要繁殖地?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '文蛤',
      },
      {
        id: 'B',
        text: '中國鱟',
      },
      {
        id: 'C',
        text: '麗彩招潮蟹',
      },
      {
        id: 'D',
        text: '濱螺',
      },
    ],
  },
  {
    status: 'new',
    id: 2,
    board_no: 21,
    question: '在水口能找到多少種紅樹?',
    answer_id: 'C',
    choices: [
      {
        id: 'A',
        text: '1',
      },
      {
        id: 'B',
        text: '3',
      },
      {
        id: 'C',
        text: '6',
      },
      {
        id: 'D',
        text: '8',
      },
    ],
  },
  {
    status: 'new',
    id: 3,
    board_no: 21,
    question: '以下哪一個不是麗彩招潮蟹的特徵?',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '背甲上混有黑色和淡藍色相間的斑紋',
      },
      {
        id: 'B',
        text: '所有麗彩招潮蟹都有一隻誇張地大的螯',
      },
      {
        id: 'C',
        text: '雄蟹有橙紅色步足',
      },
      {
        id: 'D',
        text: '雄蟹有橙紅色大螯',
      },
    ],
  },
  {
    status: 'new',
    id: 4,
    board_no: 21,
    question: '以下哪種是香港能找到的原生紅樹?',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '鹵蕨',
      },
      {
        id: 'B',
        text: '蠟燭果',
      },
      {
        id: 'C',
        text: '白骨壤',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 5,
    board_no: 21,
    question: '濱螺牠們分泌黏液依附在岩石上﹐緊閉口蓋的作用是?',
    answer_id: 'A',
    choices: [
      {
        id: 'A',
        text: '防止過熱',
      },
      {
        id: 'B',
        text: '覓食',
      },
      {
        id: 'C',
        text: '呼吸',
      },
      {
        id: 'D',
        text: '沒有作用',
      },
    ],
  },
  {
    status: 'new',
    id: 6,
    board_no: 21,
    question: '以下哪種休閒活動可能會影響水口生態？',
    answer_id: 'D',
    choices: [
      {
        id: 'A',
        text: '掘蜆',
      },
      {
        id: 'B',
        text: '玩滑翔傘',
      },
      {
        id: 'C',
        text: '採集海洋生物做標本',
      },
      {
        id: 'D',
        text: '以上皆是',
      },
    ],
  },
  {
    status: 'new',
    id: 7,
    board_no: 21,
    question: '牛背鷺和水牛是',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '寄生關係',
      },
      {
        id: 'B',
        text: '共生關係',
      },
      {
        id: 'C',
        text: '競爭關係',
      },
      {
        id: 'D',
        text: '沒有關係',
      },
    ],
  },
  {
    status: 'new',
    id: 8,
    board_no: 21,
    question: '牛背鷺的主要食物是',
    answer_id: 'B',
    choices: [
      {
        id: 'A',
        text: '農地的植物',
      },
      {
        id: 'B',
        text: '昆蟲',
      },
      {
        id: 'C',
        text: '濕地的兩棲動物',
      },
      {
        id: 'D',
        text: '河溪的魚',
      },
    ],
  },
];

export const WORKSHOP_QUESTIONS = {
  2: SUNNY_BAY_QUESTIONS,
  3: THE_BROTHERS_QUESTIONS,
  9: AIRPORT_QUESTIONS,
  13: DISCOVERY_BAY_QUESTIONS,
  14: TAI_HO_QUESTIONS,
  20: SHAM_WAT_QUESTIONS,
  21: TUNG_CHUNG_QUESTIONS,
  25: MUI_WO_QUESTIONS,
  28: TAI_O_QUESTIONS,
  31: PUI_O_QUESTIONS,
  35: CHEUNG_SHA_QUESTIONS,
  36: SHUI_HAU_QUESTIONS,
};
